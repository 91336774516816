import * as ACTIONS from "./actionTypes";

const initialValues = {
    tableHeader: [
        {id: "actions", label: "Actions"},
        {id: "createdAt", label: "Join Date"},
        {id: "status", label: "Account Status"},
        {id: "action", label: "Account Action"},
        {id: "type", label: "Account Type"},
        {id: "is_premium", label: "Is Premium"},
        {id: "full_name", label: "Name"},
        {id: "email", label: "Email"},
        {id: "gender", label: "Gender"},
        {id: "created_with", label: "Created With"},
        {id: "available_to_meet", label: "Available to meet"},
        {id: "interests", label: "Interests"}
    ],
    listingsData: {
        current_page: 0,
        total_pages: 0,
        posts: [],
        count: 0
    }
};

const MeetPeopleReducer = (state = initialValues, action: any) => {
    switch (action.type) {
        case ACTIONS.SET_MEET_PEOPLE_DATA:
            return {
                ...state,
                listingsData: {
                    posts: action?.payload?.people,
                    count: action?.payload?.count,
                    current_page: action?.payload?.current_page,
                    page_size: action?.payload?.page_size,
                    total_pages: action?.payload?.total_pages
                }
            };
        default:
            return state;
    }
};

export default MeetPeopleReducer;