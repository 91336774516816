import React from 'react';
import Linkify from 'react-linkify';
import Typography from '@material-ui/core/Typography';
import renderHTML from 'react-render-html';

export default function Tuple(props: any) {
    return (
        <div style={{
            margin: '10px',
            padding: '10px',
            display: 'inline-grid'
        }}>
            <Typography variant="h6" component="h3" style={{lineHeight: 0.5}} className="mb-4">
                {props?.label}
            </Typography>
            <Linkify>
                <p className="my-0 removeChildMargin" style={{
                    color: '#5a5a5a',
                    maxWidth: '100%',
                    maxHeight: '350px',
                    overflow: 'auto'
                }}>
                    {renderHTML(props?.value || 'NA')}
                </p>
            </Linkify>
        </div>
    );
}