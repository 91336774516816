import React from "react";
import style from "./DragAndDrop.module.scss";
import {CircularProgress, InputLabel, Paper} from "@material-ui/core";
import {useDropzone} from "react-dropzone";
import clsx from "clsx";
import ImageList from "./ImageList";

const DragAndDrop = ({onDrop, accept, images, deleteImage, label, maxFiles, isLoading}) => {
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept,
        minSize: 0,
        maxFiles
    });
    return (
        <>
            <InputLabel shrink className={style.inputLabels}>
                {label}
            </InputLabel>
            <Paper elevation={3}>
                <div {...getRootProps()} className={clsx(style.container, style.mainUploadContainer,
                    images && images.length === maxFiles ? style.disableImageUpload : "",
                    isDragActive ? style.dropZoneContainerActive : "")}>
                    <input {...getInputProps()}/>
                    {images && images.length ? <ImageList images={images} deleteImage={deleteImage}/>
                        : <div className={clsx(style.dropZoneUploadContainer,
                            images && images.length ? style.containerBorderBottom : "")}>
                            <div
                                className={clsx("d-flex align-items-center justify-content-center",
                                    style.dropZoneInputContent)}>
                                <img src={"/upload-icon.svg"} alt="upload" className="mr-1" width={60} height={60}/>
                                {isDragActive ?
                                    <p>Release to drop the files here</p>
                                    :
                                    <p>Drag and drop your files here</p>
                                }
                            </div>
                        </div>}
                    {isLoading ? <div className="d-flex align-items-center justify-content-center my-5">
                        <CircularProgress className="mr-4" size={30}/>Please wait while we are compressing your images.
                    </div> : null}
                </div>
            </Paper>
        </>
    );
};
export default DragAndDrop;