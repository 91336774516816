import * as Actions from "./actionTypes";
import axios from "axios";
import {API_PATH} from "../../constants";

export const GetMeetPeopleList = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: "post",
                url: `${API_PATH}/admin/meet-people-users`,
                headers: {
                    Authorization: token
                },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_MEET_PEOPLE_DATA,
                payload
            });
        } catch (e) {
            throw e;
        }
    };
};