import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Close} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import RenderTextField from "../../InputComponents/TextField/RenderTextField";
import _ from "lodash";

const TableHeaderFilter = ({sectionBaseUrl: pathname, label, placeholder}: any) => {

    const history = useHistory();
    const [keyword, setKeyword] = useState("");
    const delayedQuery = useCallback(_.debounce((k) => handleSearch(k), 400), []);

    useEffect(() => {
        const query = new URLSearchParams(history?.location?.search);
        const keyword = query.get('keyword');
        setKeyword(keyword)
        // eslint-disable-next-line
    }, [history?.location?.search]);

    const handleSearch = (keyword) => {
        history.replace(keyword ? {
            pathname,
            search: `?${new URLSearchParams({keyword}).toString()}`
        } : pathname);
    };

    const doSearch = e => {
        const keyword = e?.target?.value;
        setKeyword(keyword)
        delayedQuery(keyword);
    }

    const handleClear = () => {
        setKeyword("")
        delayedQuery("");
    }

    const endAdornment = keyword ? <IconButton aria-label="toggle password visibility"
                                               onClick={handleClear}
                                               edge="end">
        <Close/>
    </IconButton> : null

    return <div className="ml-5 pt-3 pb-5 d-flex align-items-center">
        <RenderTextField id="keyword"
                         value={keyword}
                         autoFocus={true}
                         autoComplete={"off"}
                         label={label || "Search"}
                         placeholder={placeholder || "Enter your search keyword"}
                         onChange={doSearch}/>

        <span className="mt-5">{endAdornment}</span>
    </div>
};

export default (TableHeaderFilter);