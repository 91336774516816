import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { fetchOneOfferData, updateOffer, uploadImages } from "../../../redux/Dashboard/actions";
import { useCookies } from "react-cookie";
import useStateRef from "react-usestateref";
import { Button, InputLabel, Typography } from "@material-ui/core";
import DragAndDrop from "../../DragAndDrop";
import { connect, useSelector } from "react-redux";
import imageCompression from "browser-image-compression";
import SelectField from "../../InputComponents/SelectField/RenderSelectField";
import TextField from "../../InputComponents/TextField/RenderTextField";
import style from "../BuySell/style.module.scss";
import { Euro } from "@material-ui/icons";
import moment from "moment";
import AutocompleteTextField from "../../InputComponents/AutocompleteTextField";
import AutoCompleteAddressComponent from "../../InputComponents/AutoCompleteAddress";
import EditorComponent from "../../InputComponents/Editor";
import { editorToolbarOptions } from "../../InputComponents/Editor/EditorToolbarOptions";
import { RootState } from "../../../redux/store";

function EditOffers(props: any) {
    const category = useSelector((store: RootState) => store?.commonAppData.categories?.offers) || [];
    const [state, setState, refState] = useStateRef({});
    const [, setProgress, progressRef] = useStateRef(0);
    const [isActive, setIsActive] = useState(false);
    const [cookies] = useCookies(["token"]);

    useEffect(() => {
        const post_id = props?.match?.params?.id;
        props?.fetchOneOfferData(cookies?.token, post_id).then(data => {
            data.images = data?.images?.map(d => ({
                id: d.img_id,
                src: d.sm,
                apiName: d.rank,
                name: "0" + d.rank,
                d
            }));
            data.categories = data?.categories?.map(d => d?.category_id);
            setState({...data, coordinates: {lat: data?.latitude, lng: data?.longitude}});
        });
        // eslint-disable-next-line
    }, [cookies?.token]);

    const getUniqueId = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
            let r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    const handleExpiryChange = (e: any) => {
        setState({...refState?.current, expiry_date: e.target.value});
    };

    const handlePriceChange = (e: any) => {
        setState({...refState?.current, price: e.target.value});
    };

    const onDrop = useCallback(async (files) => {
        if (files && files.length) {
            const currentIndex = refState?.current?.images?.length;
            const file_mame = files.map(file => file.name ? file.name : file.path ? file.path : "Unknown");
            files = files.map((file, i) => (
                new File([file], `${i + currentIndex}`, {type: file.type})
            ));
            const updateProgress = (p) => {
                if (p == 100) {
                    setProgress(progressRef.current + 1);
                    if (files.length == progressRef.current) {
                        setIsActive(false);
                        setProgress(0);
                    }
                }
            };
            setIsActive(true);
            let prom = [];
            for (let index in files) {
                prom.push(imageCompression(files[index], {maxSizeMB: 1, onProgress: updateProgress}));
            }
            Promise.all(prom).then(files => {
                files.map((file, i) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setState({
                            ...refState.current, images: [
                                ...(refState?.current?.images || []),
                                {
                                    file,
                                    id: getUniqueId(),
                                    //@ts-ignore
                                    src: e.target.result,
                                    apiName: file.name,
                                    name: file_mame[i],
                                    size: file.size ? `${((file.size / 1024) * 0.0009765625).toFixed(2)} MB` : 0
                                }
                            ]
                        });
                    };
                    reader.readAsDataURL(file);
                    return file;
                });
            });
        }
        // eslint-disable-next-line
    }, []);
    const setOwnerId = (e) => {
        setState({...refState?.current, user_id: e?.target?.value});
    };
    const handleTagChange = (e: ChangeEvent<{ value: unknown }>, value) => {
        setState({...refState?.current, tags: value});
    };
    const handleCategoryChange = (e) => {
        const category_id = e.target.value;
        setState({...refState?.current, primary_category: {category_id}});
    };

    const handleTitleChange = (e: any) => {
        setState({...refState?.current, title: e.target.value});
    };
    const setAddress = (address) => {
        setState({...refState?.current, ...address});
    };

    const setCoordinates = (coordinates) => {
        setState({...refState?.current, coordinates});
    };
    const handleDeleteImage = (id) => {
        if (id) {
            setState({...refState?.current, images: refState?.current?.images?.filter(d => d.id !== id)});
        }
    };

    const handleDescriptionChange = (value: any) => {
        setState({...refState?.current, description: value});
    };
    const getImagesData = (images, id) => {
        let formData = new FormData();
        images.forEach((image: any) => {
            formData.append("files", image?.file as any, image?.apiName);
        });
        formData.append("id", id);
        formData.append("type", "OFFER");
        return formData;
    };
    const doUpdate = () => {
        const prom = [props?.updateOffer(cookies?.token, {
            ...state,
            category_id: refState?.current?.primary_category?.category_id,
            categories: refState?.current?.tags?.map(d => d.value),
            latitude: refState?.current?.coordinates?.lat,
            longitude: refState?.current?.coordinates?.lng,
            images: refState?.current?.images?.filter(d => !d.file).map((d: any) => ({
                img_id: d?.d?.img_id,
                rank: d?.d?.rank
            }))
        })];

        if (refState?.current?.images?.filter(d => d.file)?.length) {
            prom.push(props?.uploadImages(
                cookies?.token,
                getImagesData(refState?.current?.images?.filter(d => d.file), refState?.current?.offer_id)));
        }

        Promise.all(prom).then(_ => {
            alert("Post updated");
            window?.location?.reload();
        });
    };

    return (
        <div style={{margin: "10%", marginTop: "100px", marginBottom: "50px"}}>
            <Typography variant={"h5"} style={{marginBottom: "30px"}}>
                Offer
            </Typography>
            <div style={{margin: "10px"}}>
                <DragAndDrop
                    onDrop={onDrop}
                    images={state?.images}
                    isLoading={isActive}
                    deleteImage={handleDeleteImage}
                    accept={"image/png, image/jpg, image/jpeg"}
                    maxFiles={10}
                    label="Upload Photos (up to 10 Photos)"
                />
            </div>
            <div style={{margin: "10px"}}>
                <SelectField
                    label='Category *'
                    id="category"
                    inputId="select-category"
                    value={state?.primary_category?.category_id}
                    dropdownListHeight={250}
                    onChange={handleCategoryChange}
                    dataArray={category}
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="title"
                    value={state?.title}
                    label="Enter a title *"
                    onChange={handleTitleChange}
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="title"
                    value={state?.user_id}
                    label="Owner"
                    onChange={setOwnerId}
                />
            </div>
            <div style={{margin: "10px"}}>
                <InputLabel shrink htmlFor="price" className={style.inputLabels}>
                    Enter a Price
                </InputLabel>
                <div className={style.priceInputLayout}>
                    <Euro className={style.icon} component={"svg"}/>
                    <TextField
                        id="price"
                        type="number"
                        variant="outlined"
                        value={state?.price}
                        onChange={handlePriceChange}
                        className={style.inputPriceFields}
                    />
                </div>
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="expiry"
                    type="date"
                    value={moment(state?.expiry_date).format("YYYY-MM-DD")}
                    defaultValue={moment(state?.expiry_date).format("YYYY-MM-DD")}
                    onChange={handleExpiryChange}
                    label="Expiry Date"/>
            </div>
            <div style={{margin: "10px"}}>
                <AutocompleteTextField
                    value={state?.tags || category.filter(d => state?.categories?.includes(d?.value))}
                    onChange={handleTagChange}
                    id="tags"
                    label={`Add additional tags`}
                    options={category}
                    inputPlaceholder="Search tags"
                />
            </div>
            <div style={{margin: "10px"}}>
                <EditorComponent
                    initialValue={state?.description}
                    handleDescriptionChange={handleDescriptionChange}
                    placeholder="Enter your description here..."
                    label='Describe your business/service *'
                    editorToolbarOptions={editorToolbarOptions}
                />
            </div>
            <div style={{margin: "10px"}}>
                <AutoCompleteAddressComponent
                    setAddress={setAddress}
                    defaultValue={state?.address}
                    setCoordinates={setCoordinates}
                    coordinates={state?.coordinates}
                    hideMap={false}
                    id="location"
                    autoComplete="off"
                    label='Enter the business address *'
                />
            </div>
            <div style={{margin: "10px"}}>
                <Button
                    onClick={doUpdate}
                    color={"primary"}
                    variant={"contained"}
                    style={{width: "100%"}}>
                    Update Post
                </Button>
            </div>
        </div>
    );
}

export default connect(null, {
    fetchOneOfferData,
    uploadImages,
    updateOffer
})(EditOffers);