import axios from "axios";
import {API_PATH} from "../../constants";

export const doLogin = async (data: any) => {
    try {
        const config = {
            method: 'post',
            url: `${API_PATH}/auth/admin-login`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        const response = (await axios(config as any))?.data?.data;
        if (response?.token) {
            return response?.token;
        } else {
            throw new Error("Login Failed");
        }
    } catch (e) {
        throw e;
    }
};