import * as Actions from "./actionTypes";
import axios from "axios";
import {API_PATH} from "../../constants";

export const GetUsersList = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/users`,
                headers: {
                    Authorization: token
                },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_USERS_DATA,
                payload
            });
        } catch (e) {
            throw e;
        }
    };
};

export const UpdateUserProfile = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/user`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const DeleteUser = (token: string, user_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {user_id},
                url: `${API_PATH}/admin/delete/user`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const VerifyUser = (token: string, user_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {user_id},
                url: `${API_PATH}/admin/verify/user`,
                headers: {Authorization: token}
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};