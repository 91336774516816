import imageCompression from "browser-image-compression";
import {ENV} from "./constants";

export const stripHtml = html => {
    if (typeof document !== "undefined") {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
};

export const stripHtmlWithoutTags = html => {
    return html.replace(/<\/?[^>]+(>|$)/g, "")
};

export const getUniqueId = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const goToListing = (section, categories, category_id, post_id) => {
    if (categories && categories.length) {
        const category = categories.find(d => d?.value === category_id)
        const url = ENV === 'DEVELOPMENT' ? `https://www.timexyz.com${section}/${category?.name}/${post_id}` :
            `https://www.luxembourgexpats.lu${section}/${category?.name}/${post_id}`;
        window.open(url, "_blank")
    }
};

export const onDropCallback = (files, imagesRef, setProgress, progressRef, setIsActive, setImages) => {
    if (files && files.length) {
        const currentIndex = imagesRef.current.length;
        const file_mame = files.map(file => file.name ? file.name : file.path ? file.path : "Unknown");
        files = files.map((file, i) => (
            new File([file], `${i + currentIndex}`, {type: file.type})
        ));
        const updateProgress = (p) => {
            if (p == 100) {
                setProgress(progressRef.current + 1);
                if (files.length == progressRef.current) {
                    console.log("Done");
                    setIsActive(false);
                    setProgress(0);
                }
            }
        };
        setIsActive(true);
        let prom = [];
        for (let index in files) {
            prom.push(imageCompression(files[index], {maxSizeMB: 1, onProgress: updateProgress}));
        }
        Promise.all(prom).then(files => {
            files.map((file, i) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImages(state => [
                        ...state,
                        {
                            file,
                            id: getUniqueId(),
                            //@ts-ignore
                            src: e.target.result,
                            apiName: file.name,
                            name: file_mame[i],
                            size: file.size ? `${((file.size / 1024) * 0.0009765625).toFixed(2)} MB` : 0
                        }
                    ]);
                };
                reader.readAsDataURL(file);
                return file;
            });
        });
    }
};

export const scrollToBottom = chatListId => {
    if (typeof document !== "undefined") {
        const chatList = document.getElementById(chatListId);
        if (chatList) {
            chatList.scrollTop = chatList.scrollHeight;
        }
    }
};

export const handleCharLengthLimit = (value, maxLength) => {
    if (value > maxLength) {
        return;
    }
};

export const getSharingLink = (id, category, route) => {
    if (typeof window !== "undefined") {
        return `${window?.location?.origin}${route}/${category}/${id}`;
    }
    return "";
};

export const trimMessage = message => message ? message.replace(/<style[^>]*>.*<\/style>/gm, "")
    .replace(/<script[^>]*>.*<\/script>/gm, "") : "";

export const copyToClipboard = str => {
    if (typeof window !== "undefined") {
        const el = document.createElement("textarea");
        el.value = str;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        return str;
    }
};

export const createExternalLink = (text, displayText = "", hideVisitWebsite = false) => {
    //URLs starting with http://, https://, or ftp://
    const replacePattern1 = /(\b(https?|ftp):\\[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    let replacedText: string;
    if (!hideVisitWebsite) {
        replacedText = text.replace(replacePattern1,
            "<a href='$1' target='_blank' rel='noopener noreferrer' " +
            "class='text-decoration-none'>" +
            "Visit Website</a>");

        const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2,
            "$1<a href='http://$2' target='_blank' rel='noopener noreferrer' " +
            "class='text-decoration-none'>" +
            "Visit Website</a>");
    } else if (displayText && hideVisitWebsite) {
        replacedText = text.replace(replacePattern1, "<a href='$1' target='_blank' rel='noopener noreferrer' " +
            "class='text-decoration-none'>" + displayText + "</a>");

        //URLs starting with www. (without // before it, or it'd re-link the ones done above)
        const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2,
            "$1<a href='http://$2' target='_blank' rel='noopener noreferrer'" +
            "class='text-decoration-none'>" + displayText + "</a>");
    } else {
        replacedText = text.replace(replacePattern1, "<a href='$1' target='_blank' rel='noopener noreferrer' " +
            "class='text-decoration-none'>$1</a>");

        //URLs starting with www. (without // before it, or it'd re-link the ones done above)
        const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2,
            "$1<a href='http://$2' target='_blank' rel='noopener noreferrer' " +
            "class='text-decoration-none'>$2</a>");
    }
    return replacedText;
};