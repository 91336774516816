import React from "react";
import style from "./Styles.module.scss";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {InputLabel} from "@material-ui/core";
import DateUtils from '@date-io/moment'

const RenderDatePicker = ({
                              id, name, label, showInputLabel, value, format, defaultValue, removeMarginAndPadding,
                              containerClassName, inputClassName, onChange, helperText, error, disablePast, disableFuture
                          }: any) => {
    return <div className={style.inputContainer}>
        {showInputLabel ? <InputLabel shrink htmlFor={id} className={style.inputLabels}>
            {label}
        </InputLabel> : null}
        <MuiPickersUtilsProvider utils={DateUtils}>
            <DatePicker autoOk
                        variant="inline"
                        id={id}
                        name={name}
                        label={!showInputLabel ? label : ""}
                        fullWidth
                        value={value}
                        format={format}
                        disablePast={disablePast}
                        disableFuture={disableFuture}
                        className={containerClassName}
                        helperText={helperText}
                        error={error}
                        inputVariant="outlined"
                        inputProps={{className: `${inputClassName} ${style.datePicker}`}}
                        defaultValue={defaultValue}
                        onChange={(d) => onChange(d)}/>
        </MuiPickersUtilsProvider>
    </div>;
};
export default RenderDatePicker;