import {SET_CATEGORIES_DATA, SET_MEET_PEOPLE_INTERESTS_DATA, SHOW_SNACKBAR} from "./actionTypes";
import {createAllCommonCategories, sortData} from "./action";

const initialValues = {
    snackbar: {
        isShowing: false,
        message: "",
        type: ""
    },
    categories: {
        services: [],
        buySell: [],
        offers: [],
        events: [],
        stories: [],
        realEstate: [],
        requestOffer: [],
    },
    meetPeople: [],
};

export const CommonAppData = (state = initialValues, action) => {
    const payload = action?.payload;
    switch (action.type) {
        case SHOW_SNACKBAR:
            return {...state, snackbar: {...payload}};
        case SET_CATEGORIES_DATA:
            return {...state, categories: createAllCommonCategories(payload)};
        case SET_MEET_PEOPLE_INTERESTS_DATA:
            const categoryListData = payload && payload.length ? sortData(payload, "url_identifier").map(d => ({
                title: d?.label,
                value: d?.interest_id,
                name: d?.url_identifier
            })) : []
            return {...state, meetPeople: categoryListData};
        default:
            return state;
    }
};