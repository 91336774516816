import {
    OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL,
    OPEN_EXTEND_SUBSCRIPTIONS_MODAL,
    OPEN_REQUEST_OFFER_MODAL,
    TOGGLE_CHANGE_OWNERSHIP_MODAL
} from "./actionTypes";

const obj = {
    isOpen: false,
    config: {},
    data: {},
    callBack: (flag, data) => {
    }
};

const initialValues = {
    changeOwnershipModal: {...obj},
    extendSubscriptionModal: {...obj},
    activateSubscriptionModal: {...obj},
    requestOfferModal: {...obj}
};

export const ModalReducer = (state = initialValues, action) => {
    switch (action.type) {
        case TOGGLE_CHANGE_OWNERSHIP_MODAL:
            return Object.assign({}, state, {changeOwnershipModal: action.payload});
        case OPEN_EXTEND_SUBSCRIPTIONS_MODAL:
            return Object.assign({}, state, {extendSubscriptionModal: action.payload});
        case OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL:
            return Object.assign({}, state, {activateSubscriptionModal: action.payload});
        case OPEN_REQUEST_OFFER_MODAL:
            return Object.assign({}, state, {requestOfferModal: action.payload});
        default:
            return state;
    }
};