import React from "react";
import {GoogleApiWrapper, InfoWindow, Map, Marker} from "google-maps-react";

interface IAutoCompleteProps {
    google?: any,
    zoom: number,
    address: string,
    containerStyle: object,
    coordinates: any,
    visible: boolean
}

let localContainerStyle = {
    position: "relative",
    left: 0,
    top: 0,
    minHeight: "300px",
    width: "100%",
    height: "100%",
    margin: "1rem 0"
};


const MapComponent = ({google, zoom, visible, address, containerStyle, coordinates}: IAutoCompleteProps) => {

    const [activeMarker, setActiveMarker] = React.useState({});
    const [showingInfoWindow, setShowingInfoWindow] = React.useState(true);
    localContainerStyle = {...localContainerStyle, ...containerStyle};

    const onMarkerClick = (props, marker) => {
        setActiveMarker(marker);
        setShowingInfoWindow(true);
    };

    const onInfoWindowClose = flag => {
        setShowingInfoWindow(flag);
    };

    const onMapClicked = () => {
        if (showingInfoWindow) {
            setActiveMarker(null);
            setShowingInfoWindow(false);
        }
    };

    const onMapReady = (mapProps, map) => {
        if (map) {
            map.setOptions({
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP]
                }
            });
        }
    };

    return (// @ts-ignore
        <Map zoom={zoom}
             google={google}
             visible={visible}
             center={coordinates}
             onClick={onMapClicked}
             initialCenter={coordinates}
             containerStyle={localContainerStyle}
             onReady={(mapProps, map) => onMapReady(mapProps, map)}>

            <Marker
                // @ts-ignore
                name="Current Location"
                onClick={onMarkerClick}
                position={coordinates}/>

            <InfoWindow google={google}
                        map={google.maps}
                        marker={activeMarker}
                        mapCenter={coordinates}
                        visible={showingInfoWindow}
                // @ts-ignore
                        onClose={onInfoWindowClose}>
                <p>{address}</p>
            </InfoWindow>
        </Map>);
};

export default React.memo(GoogleApiWrapper({
    apiKey: "AIzaSyBgwtYZnNY28hqdYdNQVr7r0u57fO0XiFY"
})(MapComponent as any));