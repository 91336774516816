import * as Actions from "./actionTypes";
import axios from "axios";
import {API_PATH} from "../../constants";

export const GetBuySellList = (token: string, page_number: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/buy-sell`,
                headers: {
                    Authorization: token
                },
                data: {page_number}
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_BUY_SELL_DATA,
                payload
            });
        } catch (e) {
            throw e;
        }
    };
};

export const DeleteBuySell = (token: string, buy_sell_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {buy_sell_id},
                url: `${API_PATH}/admin/delete/buy-sell`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const UpdateBuySell = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/buy-sell`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const VerifyBuySell = (token: string, buy_sell_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {buy_sell_id},
                url: `${API_PATH}/admin/verify/buy-sell`,
                headers: {Authorization: token}
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};