import {ContentState, convertFromHTML, EditorState} from "draft-js";
import Autolinker from "autolinker";
import {stateToHTML} from "draft-js-export-html";
import React, {useEffect, useState} from "react";
import {InputLabel} from "@material-ui/core";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import style from "./Editor.module.scss";
import { Editor } from "react-draft-wysiwyg";

function EditorComponent (props:any) {

    const [editorState, setEditorState] = useState();

    useEffect(() => {
        const initEditorState = (initialValue) => {
            const contentBlock = convertFromHTML(initialValue);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
                return EditorState.createWithContent(contentState);
            }
        };
        if (!editorState && props?.initialValue) {
            setEditorState(initEditorState(props?.initialValue));
        }
        // eslint-disable-next-line
    }, [props?.initialValue]);

    const handleEditorChange = (editorState) => {
        setEditorState(editorState);
        changeValue(editorState, props);
    };

    const convertLink = (value) => {
        return Autolinker.link(value);
    };

    const changeValue = (editorState, props) => {
        const {handleDescriptionChange} = props;

        let value = convertLink(stateToHTML(editorState.getCurrentContent()));
        if (typeof handleDescriptionChange === "function") {
            handleDescriptionChange(value);
        }
    };

    const {label, placeholder, editorToolbarOptions} = props;
    return (
        <>
            <InputLabel shrink className={style.inputLabels}>
                {label}
            </InputLabel>
            <div className={style.editor}>
                <Editor
                    // @ts-ignore
                    placeholder={placeholder ? placeholder : "Enter your description here..."}
                    toolbar={editorToolbarOptions}
                    editorState={editorState}
                    wrapperClassName={`${style.wysiwygWrapper} ${props.error ? style.error : null}`}
                    toolbarClassName={style.wysiwygToolbar}
                    editorClassName={style.wysiwygContent}
                    onEditorStateChange={(ed: any) => handleEditorChange(ed)}/>
            </div>
            {props.error ? <InputLabel error={true}>Please enter a valid description.</InputLabel> : null}
        </>
    );
}

export default EditorComponent;