import React from "react";
import style from "./RenderMultiToggleSwitch.module.scss";
import { IconButton, InputLabel } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Clear } from "@material-ui/icons";

interface DataArray {
    title: any,
    value: any,
    disabled?: boolean,
    selected?: boolean,
    hideRemoveIcon?: boolean
}

interface IRenderToggleSwitch {
    id?: any,
    label?: any,
    value?: any,
    toggleButtonGroupId?: any,
    labelClassName?: string,
    buttonGroupClassName?: string,
    checkedClassName?: string,
    buttonClassName?: string,
    helperText?: string,
    toggleButtonLabelClassName?: string,
    error?: boolean,
    dataArray: DataArray[],
    onChange?: (e: any, data: any, groupId: any) => void
    onDeleteItem?: (id) => void
}

const RenderMultiToggleSwitch = ({
                                     id, label, value, toggleButtonGroupId, checkedClassName, labelClassName,
                                     buttonGroupClassName, buttonClassName, onChange,
                                     dataArray, error, helperText, onDeleteItem,
                                     toggleButtonLabelClassName
                                 }: IRenderToggleSwitch) => {

    return <div className={style.inputContainer}>
        <InputLabel htmlFor={id} className={`${labelClassName} ${style.label}`}>
            {label}
        </InputLabel>
        <ToggleButtonGroup value={value}
                           onChange={(a, b) => {
                               if (typeof onChange !== "undefined" && typeof onChange === "function") {
                                   onChange(a, b, toggleButtonGroupId);
                               }
                           }}
                           aria-label={label}
                           className={`w-100 ${buttonGroupClassName} ${style.toggleButtonGroup}
                               ${error ? style.error : ""}`}>
            {dataArray.map((d) => {
                    const selected = (d?.selected || (Array.isArray(value) && value.includes(d?.value)));
                    return <ToggleButton key={d?.value}
                                         aria-label={d?.title}
                                         selected={selected}
                                         value={d?.value}
                                         classes={{label: `${toggleButtonLabelClassName}`}}
                                         disabled={d?.disabled}
                                         className={`${buttonClassName} ${style.toggleButton}
                                                 ${selected ? checkedClassName ? checkedClassName : style.checked : ""}`}>
                        {d?.title}
                        {typeof onDeleteItem !== "undefined" && typeof onDeleteItem === "function" && !d?.hideRemoveIcon ?
                            <IconButton className={`p-0 ml-2 ${style.delete}`} color="inherit"
                                        onClick={() => onDeleteItem(d?.value)}>
                                <Clear/>
                            </IconButton> : null}
                    </ToggleButton>;
                }
            )}
        </ToggleButtonGroup>
        {error ? <InputLabel error={true}>{helperText}</InputLabel> : null}
    </div>;
};
export default RenderMultiToggleSwitch;