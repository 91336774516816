import * as Actions from "./actionTypes";
import axios from "axios";
import {API_PATH} from "../../constants";

export const GetEventsList = (token: string, page_number: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/events`,
                headers: {
                    Authorization: token
                },
                data: {page_number}
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_EVENTS_DATA,
                payload
            });
            return {type: 'success', response: payload}
        } catch (e) {
            throw e;
        }
    };
};

export const GetOneEvent = (token: string, post_id: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/event/${post_id}`,
                headers: {}
            };
            return (await axios(config as any))?.data?.data;
        } catch (e) {
            throw e;
        }
    };
};

export const UpdateEvents = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/events`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            return {type: "failed"}
        }
    };
};

export const DeleteEvent = (token: string, event_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {event_id},
                url: `${API_PATH}/admin/delete/event`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};