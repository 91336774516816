import * as Actions from "./actionTypes";
import axios from "axios";
import {API_PATH} from "../../constants";

export const UpdatePost = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/request-offer`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const GetRequestOfferList = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: "post",
                url: `${API_PATH}/admin/request-offer`,
                headers: {
                    Authorization: token
                },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_REQUEST_OFFER_DATA,
                payload
            });
        } catch (e) {
            throw e;
        }
    };
};

export const UpdateRequestOffer = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/request-offer`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const DeleteRequestOfferPost = (token: string, request_offer_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {request_offer_id},
                url: `${API_PATH}/admin/delete/request-offer`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};