import React from "react";
import style from "./RenderToggleSwitch.module.scss";
import {InputLabel} from "@material-ui/core";
import clsx from "clsx";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";

interface DataArray {
    title: any,
    value: any,
    disabled?: boolean,
    selected?: boolean
}

interface IRenderToggleSwitch {
    id?: string,
    label: string,
    value?: any,
    single?: boolean,
    helperText?:string,
    error?:boolean,
    toggleButtonGroupId?: any,
    labelClassName?: string,
    buttonGroupClassName?: string,
    buttonClassName?: string,
    dataArray: DataArray[],
    onChange?: (e: any, data: any, groupId: any) => void
}

const RenderToggleSwitch = ({id, label, value, toggleButtonGroupId, labelClassName, buttonGroupClassName, buttonClassName, onChange, dataArray, error, helperText}: IRenderToggleSwitch) => {
    return dataArray.length ?
        <div className={style.inputContainer}>
            <InputLabel htmlFor={id} className={clsx(labelClassName, style.label)}>
                {label}
            </InputLabel>
            <ToggleButtonGroup exclusive
                               id={toggleButtonGroupId || ""}
                               value={value}
                               onChange={(a, b) => {
                                   if (typeof onChange !== "undefined" && typeof onChange === "function") {
                                       onChange(a, b, toggleButtonGroupId);
                                   }
                               }}
                               aria-label={label}
                               className={error ? clsx("w-100", buttonGroupClassName, style.error) : clsx("w-100", buttonGroupClassName, style.toggleButtonGroup)}>
                {dataArray.map((d) => {
                        const selected = (d?.selected || d?.value === value);
                        return <ToggleButton key={d?.value}
                                             aria-label={d?.title}
                                             selected={selected}
                                             value={d?.value}
                                             disabled={d?.disabled}
                                             className={clsx(buttonClassName, style.toggleButton,
                                                 (selected ? style.checked : ""))}>
                            {d?.title}
                        </ToggleButton>;
                    }
                )}
            </ToggleButtonGroup>
            {error ? <InputLabel error={true}>{helperText}</InputLabel> : null}
        </div> : null;
};
export default RenderToggleSwitch;