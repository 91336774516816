import * as React from "react";
import style from "./RenderAutocompleteTextField.module.scss";
import {InputLabel, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

interface DataArray {
    title: string,
    value: string
}

interface IRenderAutocompleteTextFieldProps {
    id?: string,
    label: string | JSX.Element,
    value?: any,
    variant?: any,
    className?: string,
    multiple?: boolean,
    filterSelectedOptions?: boolean,
    disableCloseOnSelect?: boolean,
    options: DataArray[],
    inputPlaceholder?: string,
    autoComplete?: string,
    error?: boolean,
    hideLabel?: boolean,
    helperText?: string,
    paperClass?: string,
    onChange?: (e: object, value: any) => void
    onBlur?: (e: object) => void
}

const RenderAutocompleteTextField = ({
                                         id, disableCloseOnSelect = true, options, multiple = true, filterSelectedOptions = true, label, value,
                                         className, variant, onChange, inputPlaceholder, error, helperText, paperClass, hideLabel
                                     }: IRenderAutocompleteTextFieldProps) => {

    return <div className={style.inputContainer}>
        {!hideLabel ? <InputLabel shrink htmlFor={id} className={style.inputLabels}>
            {label}
        </InputLabel> : null}
        <Autocomplete multiple={multiple}
                      disableCloseOnSelect={disableCloseOnSelect}
                      filterSelectedOptions={filterSelectedOptions}
                      id={id}
                      value={value}
                      options={options && options.length ? options : []}
                      onChange={onChange}
                      classes={{paper: paperClass}}
                      getOptionLabel={(option) => option.title}
                      className={className ? className : style.inputFields}
                      renderInput={(params) => <TextField {...params}
                                                          error={error}
                                                          helperText={helperText}
                                                          variant={variant ? variant : "outlined"}
                                                          placeholder={inputPlaceholder ? inputPlaceholder : "Search tags"}/>}/>
    </div>;
};
export default RenderAutocompleteTextField;