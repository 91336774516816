import React, { useCallback, useEffect, useState } from "react";
import { fetchOneRealEstate, updateRealEstate, uploadImages } from "../../../redux/Dashboard/actions";
import attributesData from "../../../attributes.json";
import optionsData from "../../../options.json";
import useStateRef from "react-usestateref";
import { useCookies } from "react-cookie";
import { connect, useSelector } from "react-redux";
import imageCompression from "browser-image-compression";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, InputLabel, Typography } from "@material-ui/core";
import DragAndDrop from "../../DragAndDrop";
import ToggleSwitch from "../../InputComponents/ToggleSwitch/RenderToggleSwitch";
import SelectField from "../../InputComponents/SelectField/RenderSelectField";
import TextField from "../../InputComponents/TextField/RenderTextField";
import moment from "moment";
import style from "../BuySell/style.module.scss";
import { Euro, ExpandMore } from "@material-ui/icons";
import AutoCompleteAddressComponent from "../../InputComponents/AutoCompleteAddress";
import EditorComponent from "../../InputComponents/Editor";
import { editorToolbarOptions } from "../../InputComponents/Editor/EditorToolbarOptions";
import RenderMultiToggleSwitch from "../../InputComponents/MultiToggleSwitch/RenderMultiToggleSwitch";
import clsx from "clsx";
import { RootState } from "../../../redux/store";

function EditRealEstate(props: any) {

    const typeList = [
        {title: "Rent", value: "FOR_RENT", disabled: false},
        {title: "Sale", value: "FOR_SALE", disabled: false}
    ];

    const constructionTypeList = [
        {title: "Old", value: "OLD", disabled: false},
        {title: "New", value: "NEW", disabled: false}
    ];

    let handledByList = [
        {title: "Agent", value: "AGENT", disabled: false},
        {title: "Owner", value: "OWNER", disabled: false}
    ];

    let foundedList = [];
    foundedList.push({title: "Select Founded Year", value: "select", disabled: true});
    for (let i = 2030; i >= 1901; i--) {
        foundedList.push({title: i, value: i, disabled: false, selected: new Date().getFullYear() === i});
    }

    const [state, setState, refState] = useStateRef({});
    const [, setProgress, progressRef] = useStateRef(0);
    const [isActive, setIsActive] = useState(false);
    const [property_info, setPropertyInfo] = React.useState({});
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [cookies] = useCookies(["token"]);

    const category = useSelector((store: RootState) => store?.commonAppData.categories?.realEstate) || [];
    const sub_categories = category.find(d => d?.value === state?.primary_category?.category_id)?.subCategories;

    useEffect(() => {
        const post_id = props?.match?.params?.id;
        props?.fetchOneRealEstate(cookies?.token, post_id).then(data => {
            data.images = data?.images?.map(d => ({
                id: d.img_id,
                src: d.sm,
                apiName: d.rank,
                name: "0" + d.rank,
                d
            }));
            data.categories = data?.categories?.map(d => d?.category_id);
            setState({
                ...data,
                coordinates: {lat: data?.latitude, lng: data?.longitude},
                sub_categories: [data?.sub_categories?.[0]?.sub_category_id]
            });
            if (data?.property_info && data?.property_info.length) {
                let propertyData = data?.property_info.reduce((rv, x) => {
                    (rv[x["attribute_id"]] = rv[x["attribute_id"]] || []).push(x?.option_id);
                    return rv;
                }, {});
                setPropertyInfo(propertyData);
                setExpanded(!!Object.keys(propertyData).length);
            }
        });
        // eslint-disable-next-line
    }, [cookies?.token]);

    const getUniqueId = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
            let r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    const handleSelectionChange = (event: React.MouseEvent<HTMLElement>, value: [], toggleButtonGroupId) => {
        if (!value) {
            const info = {...property_info};
            delete info[toggleButtonGroupId];
            setPropertyInfo(info);
        } else {
            let data = Object.assign({}, property_info, {[toggleButtonGroupId]: typeof value === "number" ? [value] : value});
            setPropertyInfo(data);
        }
    };

    const handleTitleChange = (e: any) => {
        setState({...refState?.current, title: e.target.value});
    };

    const onDrop = useCallback(async (files) => {
        if (files && files.length) {
            const currentIndex = refState?.current?.images?.length;
            const file_mame = files.map(file => file.name ? file.name : file.path ? file.path : "Unknown");
            files = files.map((file, i) => (
                new File([file], `${i + currentIndex}`, {type: file.type})
            ));
            const updateProgress = (p) => {
                if (p == 100) {
                    setProgress(progressRef.current + 1);
                    if (files.length == progressRef.current) {
                        setIsActive(false);
                        setProgress(0);
                    }
                }
            };
            setIsActive(true);
            let prom = [];
            for (let index in files) {
                prom.push(imageCompression(files[index], {maxSizeMB: 1, onProgress: updateProgress}));
            }
            Promise.all(prom).then(files => {
                files.map((file, i) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setState({
                            ...refState.current, images: [
                                ...(refState?.current?.images || []),
                                {
                                    file,
                                    id: getUniqueId(),
                                    //@ts-ignore
                                    src: e.target.result,
                                    apiName: file.name,
                                    name: file_mame[i],
                                    size: file.size ? `${((file.size / 1024) * 0.0009765625).toFixed(2)} MB` : 0
                                }
                            ]
                        });
                    };
                    reader.readAsDataURL(file);
                    return file;
                });
            });
        }
        // eslint-disable-next-line
    }, []);

    const handlePropertyTypeChange = (e, value) => {
        setState({...refState?.current, type: value});
    };

    const handleCategoryChange = (e) => {
        const category_id = e.target.value;
        setState({...refState?.current, primary_category: {category_id}});
    };

    const handleSubCategoryChange = (e) => {
        const sub_category_id = e.target.value;
        setState({...refState?.current, sub_categories: [sub_category_id]});
    };

    const handleDescriptionChange = (value: any) => {
        setState({...refState?.current, description: value});
    };

    const handleDeleteImage = (id) => {
        if (id) {
            setState({...refState?.current, images: refState?.current?.images?.filter(d => d.id !== id)});
        }
    };

    const handleConstructionChange = (e, value) => {
        setState({...refState?.current, construction: value});
    };

    const setAddress = (address) => {
        setState({...refState?.current, ...address});
    };

    const setCoordinates = (coordinates) => {
        setState({...refState?.current, coordinates});
    };

    const handleConstructionDateChange = (e) => {
        setState({...refState?.current, construction_date: e.target.value});
    };

    const handleAvailableChange = (e) => {
        setState({...refState?.current, available_from: e.target.value});
    };

    const handlePriceChange = (e: any) => {
        setState({...refState?.current, price: e.target.value});
    };

    const handleMaintenancePriceChange = (e: any) => {
        setState({...refState?.current, maintenance_price: e.target.value});
    };

    const handlePropertyHandledByChange = (e: any, value) => {
        setState({...refState?.current, handled_by: value});
    };

    const handleFeeDescChange = (e: any) => {
        setState({...refState?.current, fee_desc: e?.target?.value});
    };

    const handleLivingAreaChange = (e: any) => {
        setState({...refState?.current, living_area: e?.target?.value});
    };

    const handleGardenAreaChange = (e: any) => {
        setState({...refState?.current, garden_area: e?.target?.value});
    };

    const handleBalconyAreaChange = (e: any) => {
        setState({...refState?.current, balcony_area: e?.target?.value});
    };

    const handleFloorNumChange = (e: any) => {
        setState({...refState?.current, num_floors: e?.target?.value});
    };

    const handleBathroomNumChange = (e: any) => {
        setState({...refState?.current, num_bathrooms: e?.target?.value});
    };

    const handleKitchenNumChange = (e: any) => {
        setState({...refState?.current, num_kitchens: e?.target?.value});
    };

    const handleBedroomNumChange = (e: any) => {
        setState({...refState?.current, num_bedrooms: e?.target?.value});
    };

    const handleRoomNumChange = (e: any) => {
        setState({...refState?.current, num_rooms: e?.target?.value});
    };

    const handleExpandChange = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };

    const handleExpiryChange = (e: any) => {
        setState({...refState?.current, expiry_date: e.target.value});
    };

    const getImagesData = (images, id) => {
        let formData = new FormData();
        images.forEach((image: any) => {
            formData.append("files", image?.file as any, image?.apiName);
        });
        formData.append("id", id);
        formData.append("type", "REAL_ESTATE");
        return formData;
    };

    const createRealEstateAttributes = (attributes, optionsData) => {
        const singleType = [], multipleType = [];
        if (attributes.length && optionsData.length) {
            attributes.forEach(a => {
                const options = [...optionsData.filter(o => {
                    if (a?.attribute_id === o?.attribute_id) {
                        o.title = o?.label;
                        o.value = o?.option_id;
                        o.value2 = o?.attribute_id;
                        return o;
                    }
                    return null;
                })];
                if (a?.type === "MULTI") {
                    multipleType.push({title: a?.label, value: a?.attribute_id, type: a?.type, options});
                } else {
                    singleType.push({title: a?.label, value: a?.attribute_id, type: a?.type, options});
                }
            });
        }
        return {singleType, multipleType};
    };
    const {singleType, multipleType} = createRealEstateAttributes(attributesData, optionsData);
    const createAdditionalInformation = () => {
        return <Grid container spacing={3} className={clsx("my-1", style.innerGridContainer)}>
            {multipleType.map((d, i) => {
                return <Grid key={"multi" + i}
                             item xs={12}
                             className={clsx(i > 0 ? "mt-2" : "")}>
                    <RenderMultiToggleSwitch
                        id="multipleAdditionalInformationData"
                        key={`multipleAdditionalInformationData_${i}`}
                        toggleButtonGroupId={d?.value}
                        label={`${d?.title} (${d?.type === "MULTI" ? "Multiple Selection" : ""})`}
                        value={property_info[d?.value]}
                        labelClassName={style.labelClassName}
                        buttonGroupClassName={style.buttonGroupClassName}
                        buttonClassName={style.buttonClassName}
                        onChange={handleSelectionChange}
                        dataArray={d?.options}
                    />
                </Grid>;
            })}
            {singleType.map((d, i) => {
                return <Grid key={"single" + i}
                             item xs={12}
                             className={clsx(i > 0 ? "mt-2" : "")}>
                    <ToggleSwitch
                        id="singleAdditionalInformationData"
                        key={`singleAdditionalInformationData_${i}`}
                        toggleButtonGroupId={d?.value}
                        label={`${d?.title} (${d?.type === "SINGLE" ? "Single Selection" : ""})`}
                        value={typeof property_info[d?.value] !== "undefined" && property_info[d?.value] ? property_info[d?.value][0] : ""}
                        labelClassName={style.labelClassName}
                        buttonGroupClassName={style.buttonGroupClassName}
                        buttonClassName={style.buttonClassName}
                        onChange={handleSelectionChange}
                        dataArray={d?.options}
                    />
                </Grid>;
            })}
        </Grid>;
    };

    const doUpdate = () => {
        let arr = [];
        Object.values(property_info).forEach(d => {
            if (Array.isArray(d)) {
                arr.push(d);
            } else {
                arr.push([d]);
            }
        });
        const property_info_arr = [].concat.apply([], arr);
        const prom = [props?.updateRealEstate(cookies?.token, {
            ...state,
            category_id: refState?.current?.primary_category?.category_id,
            categories: refState?.current?.tags?.map(d => d.category_id),
            latitude: refState?.current?.coordinates?.lat,
            longitude: refState?.current?.coordinates?.lng,
            property_info: (property_info_arr.length ? property_info_arr : []),
            images: refState?.current?.images?.filter(d => !d.file).map((d: any) => ({
                img_id: d?.d?.img_id,
                rank: d?.d?.rank
            }))
        })];

        if (refState?.current?.images?.filter(d => d.file)?.length) {
            prom.push(props?.uploadImages(
                cookies?.token,
                getImagesData(refState?.current?.images?.filter(d => d.file), refState?.current?.property_id)));
        }

        Promise.all(prom).then(_ => {
            alert("Post updated");
            window?.location?.reload();
        });
    };

    return (
        <div style={{margin: "10%", marginTop: "100px", marginBottom: "50px"}}>
            <Typography variant={"h5"} style={{marginBottom: "30px"}}>
                Real Estate
            </Typography>
            <div style={{margin: "10px"}}>
                <DragAndDrop
                    onDrop={onDrop}
                    images={state?.images}
                    isLoading={isActive}
                    deleteImage={handleDeleteImage}
                    accept={"image/png, image/jpg, image/jpeg"}
                    maxFiles={10}
                    label="Upload Photos (up to 10 Photos)"
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="title"
                    value={state?.title}
                    label="Enter a title *"
                    onChange={handleTitleChange}
                />
            </div>
            <div style={{margin: "10px"}}>
                <ToggleSwitch
                    id="type"
                    label='Type *'
                    value={state?.type}
                    buttonClassName='w-100'
                    helperText={"Type is mandatory."}
                    onChange={handlePropertyTypeChange}
                    dataArray={typeList}
                />
            </div>
            <div style={{margin: "10px"}}>
                <SelectField
                    label='Category *'
                    id="category"
                    inputId="select-category"
                    value={state?.primary_category?.category_id}
                    dropdownListHeight={250}
                    onChange={handleCategoryChange}
                    dataArray={category}
                />
            </div>
            <div style={{margin: "10px"}}>
                <SelectField
                    label='Sub Category *'
                    id="sub_category"
                    inputId="select-sub-category"
                    value={state?.sub_categories?.[0]}
                    dropdownListHeight={250}
                    onChange={handleSubCategoryChange}
                    dataArray={sub_categories}
                />
            </div>
            <div style={{margin: "10px"}}>
                <ToggleSwitch
                    id="construction"
                    label="Construction"
                    value={state?.construction}
                    buttonClassName='w-100'
                    onChange={handleConstructionChange}
                    dataArray={constructionTypeList}
                />
            </div>
            <div style={{margin: "10px"}}>
                <SelectField
                    id="construction_date"
                    label="Construction Year"
                    inputId="select-construction-year"
                    dropdownListHeight={250}
                    value={state?.construction_date}
                    onChange={handleConstructionDateChange}
                    dataArray={foundedList}/>
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="available_from"
                    type="date"
                    value={moment(state?.available_from).format("YYYY-MM-DD")}
                    defaultValue={moment(state?.available_from).format("YYYY-MM-DD")}
                    onChange={handleAvailableChange}
                    label="Available From"/>
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="expiry_date"
                    type="date"
                    value={moment(state?.expiry_date).format("YYYY-MM-DD")}
                    defaultValue={moment(state?.expiry_date).format("YYYY-MM-DD")}
                    onChange={handleExpiryChange}
                    label="Expiry Date"/>
            </div>
            <div style={{margin: "10px"}}>
                <InputLabel shrink htmlFor="price" className={style.inputLabels}>
                    Enter a Price
                </InputLabel>
                <div className={style.priceInputLayout}>
                    <Euro className={style.icon} component={"svg"}/>
                    <TextField
                        id="price"
                        type="number"
                        variant="outlined"
                        value={state?.price}
                        onChange={handlePriceChange}
                        className={style.inputPriceFields}
                    />
                </div>
            </div>
            <div style={{margin: "10px"}}>
                <InputLabel shrink htmlFor="price" className={style.inputLabels}>
                    Enter a Maintenance Price
                </InputLabel>
                <div className={style.priceInputLayout}>
                    <Euro className={style.icon} component={"svg"}/>
                    <TextField
                        id="maintenance_price"
                        type="number"
                        variant="outlined"
                        value={state?.maintenance_price}
                        onChange={handleMaintenancePriceChange}
                        className={style.inputPriceFields}
                    />
                </div>
            </div>
            <div style={{margin: "10px"}}>
                <ToggleSwitch
                    id="handled_by"
                    label="Property Handled by"
                    value={state?.handled_by}
                    helperText={"Please select who handles the property"}
                    buttonClassName='w-100'
                    onChange={handlePropertyHandledByChange}
                    dataArray={handledByList}
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="fee_desc"
                    multiline={true}
                    value={state?.fee_desc}
                    label="Describe agency fee *"
                    onChange={handleFeeDescChange}
                />
            </div>
            <div style={{margin: "10px"}}>
                <EditorComponent
                    initialValue={state?.description}
                    handleDescriptionChange={handleDescriptionChange}
                    placeholder="Enter your description here..."
                    label='Describe your business/service *'
                    editorToolbarOptions={editorToolbarOptions}
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="living_area"
                    label={"Living Area"}
                    type="number"
                    variant="outlined"
                    value={state?.living_area}
                    onChange={handleLivingAreaChange}
                    className={style.inputPriceFields}
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="garden_area"
                    label={"Garden Area"}
                    type="number"
                    variant="outlined"
                    value={state?.garden_area}
                    onChange={handleGardenAreaChange}
                    className={style.inputPriceFields}
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="balcony_area"
                    label={"Balcony Area"}
                    type="number"
                    variant="outlined"
                    value={state?.balcony_area}
                    onChange={handleBalconyAreaChange}
                    className={style.inputPriceFields}
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="num_bathrooms"
                    label={"Num of Bathroom"}
                    type="number"
                    variant="outlined"
                    value={state?.num_bathrooms}
                    onChange={handleBathroomNumChange}
                    className={style.inputPriceFields}
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="num_rooms"
                    label={"Num of Rooms"}
                    type="number"
                    variant="outlined"
                    value={state?.num_rooms}
                    onChange={handleRoomNumChange}
                    className={style.inputPriceFields}
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="num_bedrooms"
                    label={"Num of Bed Rooms"}
                    type="number"
                    variant="outlined"
                    value={state?.num_bedrooms}
                    onChange={handleBedroomNumChange}
                    className={style.inputPriceFields}
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="num_kitchens"
                    label={"Num of Kitchen"}
                    type="number"
                    variant="outlined"
                    value={state?.num_kitchens}
                    onChange={handleKitchenNumChange}
                    className={style.inputPriceFields}
                />
            </div>
            <div style={{margin: "10px"}}>
                <TextField
                    id="num_floors"
                    label={"Num of Floors"}
                    type="number"
                    variant="outlined"
                    value={state?.num_floors}
                    onChange={handleFloorNumChange}
                    className={style.inputPriceFields}
                />
            </div>
            <div style={{margin: "10px"}}>
                <AutoCompleteAddressComponent
                    setAddress={setAddress}
                    defaultValue={state?.address}
                    setCoordinates={setCoordinates}
                    coordinates={state?.coordinates}
                    hideMap={false}
                    id="location"
                    autoComplete="off"
                    label='Enter the business address *'
                />
            </div>
            <div style={{margin: "10px"}}>
                {singleType.length || multipleType.length ?
                    <Grid container className={clsx("my-1", style.innerGridContainer)}>
                        <Grid item xs={12} className="py-1">
                            <Accordion expanded={expanded}
                                       onChange={handleExpandChange}
                                       className={style.accordion}
                                       TransitionProps={{unmountOnExit: true}}>
                                <AccordionSummary expandIcon={<ExpandMore/>}
                                                  aria-controls="additional-information-content"
                                                  id="additional-information-header"
                                                  className={style.accordionSummary}>
                                    <label className={style.additionalSection}>
                                        Additional information
                                    </label>
                                </AccordionSummary>
                                {expanded ?
                                    <AccordionDetails>
                                        {createAdditionalInformation()}
                                    </AccordionDetails>
                                    : null}
                            </Accordion>
                        </Grid>
                    </Grid> : null}
            </div>
            <div style={{margin: "10px"}}>
                <Button
                    onClick={doUpdate}
                    color={"primary"}
                    variant={"contained"}
                    style={{width: "100%"}}>
                    Update Post
                </Button>
            </div>
        </div>
    );
}

export default connect(null, {
    fetchOneRealEstate,
    uploadImages,
    updateRealEstate
})(EditRealEstate);