import React, {useEffect, useState} from "react";
import Header from "../Header";
import {Route, Switch} from "react-router-dom";
import EditUser from "../Edit/User";
import EditClassifieds from "../Edit/Classifieds";
import EditRealEstate from "../Edit/RealEstate";
import EditBuySell from "../Edit/BuySell";
import EditOffers from "../Edit/Offers";
import EditStories from "../Edit/Stories";
import EditEvent from "../Edit/Events";
import DetailView from "../DetailView";
import {connect, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import CommonSnackbar from "../CommonSnackbar";
import {Routes} from "../../constants";
import Users from "../Users";
import BusinessListings from "../BusinessListings";
import RealEstate from "../RealEstate";
import BuySell from "../BuySell";
import Offers from "../Offers";
import Events from "../Events";
import Stories from "../Stories";
import MeetPeople from "../MeetPeople";
import Subscriptions from "../Subscriptions";
import RequestOffer from "../RequestOffer";
import SideNav from "../SideNav";
import ChangeOwnershipModal from "../Common/ChangeOwnershipModal";
import {GetCategories, GetMeetPeopleInterests} from "../../redux/Common/action";
import {useCookies} from "react-cookie";
import ExtendSubscriptionModal from "../Subscriptions/ExtendSubscriptionModal";
import ActivateSubscriptionModal from "../Common/ActivateSubscriptionModal";
import RequestOfferModal from "../RequestOffer/RequestOfferModal";

const Page = ({GetCategories, GetMeetPeopleInterests}) => {
    const snackbar = useSelector((store: RootState) => store?.commonAppData.snackbar) || {};
    const modal = useSelector((store: RootState) => store?.modal?.changeOwnershipModal) || {};
    const extendSubscriptionModal = useSelector((store: RootState) => store?.modal?.extendSubscriptionModal) || {};
    const activateSubscriptionModal = useSelector((store: RootState) => store?.modal?.activateSubscriptionModal) || {};
    const requestOfferModal = useSelector((store: RootState) => store?.modal?.requestOfferModal) || {};
    const [isLoading, setIsLoading] = useState(false);
    const [cookies] = useCookies(["token"]);
    const token = cookies["token"];

    useEffect(() => {
        setIsLoading(true);
        GetCategories(token).then(() => {
            GetMeetPeopleInterests(token).then(() => {
                setIsLoading(false);
            });
        });
        // eslint-disable-next-line
    }, []);

    return <>
        <Header/>
        <div className="d-flex mainContent">
            <SideNav/>
            <div className="w-100 overflowY-auto">
                {snackbar?.isShowing ? <CommonSnackbar/> : false}
                {modal?.isOpen ? <ChangeOwnershipModal/> : null}
                {extendSubscriptionModal?.isOpen ? <ExtendSubscriptionModal/> : null}
                {activateSubscriptionModal?.isOpen ? <ActivateSubscriptionModal/> : null}
                {requestOfferModal?.isOpen ? <RequestOfferModal/> : null}
                {isLoading ? <div className="mainLoader">
                    <div className="loader"/>
                </div> : <Switch>
                    <Route path={Routes.home} exact={true} render={(props) => {
                        return "";
                    }}/>
                    <Route path={Routes.users} exact={true} render={(props) => <Users {...props} />}/>
                    <Route path={Routes.businessListings} exact={true}
                           render={(props) => <BusinessListings {...props} />}/>
                    <Route path={Routes.realEstate} exact={true} render={(props) => <RealEstate {...props} />}/>
                    <Route path={Routes.buySell} exact={true} render={(props) => <BuySell {...props} />}/>
                    <Route path={Routes.offers} exact={true} render={(props) => <Offers {...props} />}/>
                    <Route path={Routes.events} exact={true} render={(props) => <Events {...props} />}/>
                    <Route path={Routes.stories} exact={true} render={(props) => <Stories {...props} />}/>
                    <Route path={Routes.meetPeople} exact={true} render={(props) => <MeetPeople {...props} />}/>
                    <Route path={Routes.subscriptions} exact={true} render={(props) => <Subscriptions {...props} />}/>
                    <Route path={Routes.requestOffer} exact={true} render={(props) => <RequestOffer {...props} />}/>
                    <Route path="/edit/stories/:id" render={(props) => <EditStories {...props} />}/>
                    <Route path="/edit/offers/:id" render={(props) => <EditOffers {...props} />}/>
                    <Route path="/edit/buy-sell/:id" render={(props) => <EditBuySell {...props} />}/>
                    <Route path="/edit/real-estate/:id" render={(props) => <EditRealEstate {...props} />}/>
                    <Route path="/edit/business-listings/:id" render={(props) => <EditClassifieds {...props} />}/>
                    <Route path="/edit/users/:id" render={(props) => <EditUser {...props} />}/>
                    <Route path="/edit/events/:id" render={(props) => <EditEvent {...props} />}/>
                    <Route path="/*/:id" render={(props) => <DetailView {...props} />}/>
                </Switch>}
            </div>
        </div>
    </>;
};
export default connect(null, {GetCategories, GetMeetPeopleInterests})(Page);