import React from "react";
import style from "./RenderTextField.module.scss";
import {InputAdornment, InputLabel, TextField} from "@material-ui/core";

interface IRenderTextFieldProps {
    id?: string,
    type?: string,
    label?: string,
    inputMode?: string,
    value?: any,
    rows?: number,
    className?: string,
    variant?: any,
    placeholder?: string,
    autoComplete?: string,
    onFocus?: (e: object) => void
    onChange?: (e: object) => void
    onBlur?: (e: object) => void
    onKeyPress?: (e: object) => void
    onPaste?: (e: object) => void
    onKeyUp?: (e: object) => void
    error?: boolean
    readOnly?: boolean
    multiline?: boolean
    helperText?: string
    defaultValue?: string
    inputContainerClassName?: string
    inputLabel?: string
    inputStyle?: string
    minRows?: number
    autoFocus?: boolean
    disabled?: boolean
    startAdornment?: any
    endAdornment?: any
    max?: any
}

const RenderTextField = ({
                             helperText, error, id, rows, multiline, type, readOnly, inputMode, label, value, className,
                             variant, onChange, onFocus, onBlur, placeholder = "", autoComplete, defaultValue, max, inputLabel,
                             inputStyle, autoFocus, onKeyPress, onPaste, onKeyUp, disabled, inputContainerClassName, startAdornment,
                             endAdornment, minRows
                         }: IRenderTextFieldProps) => {

    return <div className={`${style.inputContainer} ${inputContainerClassName}`}>
        {!inputLabel ? <InputLabel shrink htmlFor={id} className={style.inputLabels}>
            {label}
        </InputLabel> : null}
        <TextField id={id}
                   multiline={multiline}
                   rows={rows}
                   minRows={minRows}
                   error={error}
                   helperText={helperText}
                   type={type || "text"}
                   variant={variant || "outlined"}
                   value={value}
                   label={inputLabel}
                   disabled={disabled}
                   defaultValue={defaultValue}
                   autoFocus={autoFocus}
                   InputProps={{
                       startAdornment: startAdornment ?
                           <InputAdornment position="start">{startAdornment}</InputAdornment> : null,
                       endAdornment: endAdornment ?
                           <InputAdornment position="end">{endAdornment}</InputAdornment> : null,
                       disabled: disabled,
                       //@ts-ignore
                       inputMode: inputMode || "text", readOnly,
                       max: type === "date" ? max || "2999-12-31" : max,
                       className: `${inputStyle} ${disabled ? style.disabled : ''}`
                   }}
                   placeholder={placeholder}
                   onChange={onChange}
                   onFocus={onFocus}
                   onBlur={onBlur}
                   onPaste={onPaste}
                   onKeyUp={onKeyUp}
                   onKeyPress={onKeyPress}
                   autoComplete={autoComplete}
                   className={`${className || style.inputFields}`}/>
    </div>;
};
export default RenderTextField;