export const API_PATH = process.env.REACT_APP_API_PATH

export const Routes = {
    home: '/',
    users: '/users',
    businessListings: '/business-listings',
    realEstate: '/real-estate',
    buySell: '/buy-sell',
    offers: '/offers',
    stories: '/stories',
    events: '/events',
    meetPeople: '/meet-people',
    requestOffer: '/request-offer',
    subscriptions: '/subscriptions'
};