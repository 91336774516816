import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
    Button,
    Container,
    Grid,
    InputLabel,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography
} from "@material-ui/core";
import style from "./AddEdit.module.scss";
import { Euro } from "@material-ui/icons";
import DragAndDrop from "../../../components/DragAndDrop";
import RenderTextField from "../../../components/InputComponents/TextField/RenderTextField";
import AutoCompleteAddressComponent from "../../InputComponents/AutoCompleteAddress";
import { connect, useSelector } from "react-redux";
import { CommonDispatcher } from "../../../redux/Common/action";
import { GetOneEvent, UpdateEvents } from "../../../redux/Events/actions";
import { uploadImages } from "../../../redux/Dashboard/actions";

import TextEditor from "../../InputComponents/Editor";
import { editorToolbarOptions } from "../../InputComponents/Editor/EditorToolbarOptions";
import useStateRef from "react-usestateref";
import RenderSelectField from "../../InputComponents/SelectField/RenderSelectField";
import RenderAutocompleteTextField from "../../InputComponents/AutocompleteTextField";
import { onDropCallback, stripHtml } from "../../../utils/common";
import { SHOW_SNACKBAR } from "../../../redux/Common/actionTypes";
import { RootState } from "../../../redux/store";
import RenderDateAndTimePicker from "../../InputComponents/DateAndTimePickerComponent/RenderDateAndTimePicker";
import RenderMultiToggleSwitch from "../../InputComponents/MultiToggleSwitch/RenderMultiToggleSwitch";
import WizardFooterActionButtons from "./WizardFooterActionButtons";
import moment from "moment-timezone";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../constants";

let eventTypeList = [
    {title: "Online Event", value: "ONLINE", disabled: false},
    {title: "Event Venue", value: "VENUE", disabled: false}
];

const EditEvent = ({GetOneEvent, match, uploadImages, UpdateEvents, CommonDispatcher}) => {
    const categoryListData = useSelector((store: RootState) => store?.commonAppData.categories?.services) || [];
    const [cookies] = useCookies(["token"]);
    const id = match?.params?.id;
    const profileDetails: any = {};

    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [category, setCategory] = useState();
    const [user_id, setUserId] = useState("");
    const dateFormat = "DD-MM-YYYY hh:mm a";
    // const startDate = moment().tz(timezone).format(dateFormat)
    const [start_date_time, setStartDateTime]: any = useState(null);
    const [end_date_time, setEndDateTime]: any = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [tagName, setTagsName] = useState<object[]>([]);
    const [tagSize, setTagsSize] = useState<number>(0);
    const [MAX_TAGS_SIZE] = useState<number>(2);
    const [images, setImages, imagesRef] = useStateRef([]);
    const [progress, setProgress, progressRef] = useStateRef(0);
    const [description, setDescription] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [commonErrorMessage, setCommonErrorMessage] = useState("");
    const [isPageLoading, setPageLoading] = useState(false);
    const [title, setTitle] = useState();
    const [firstLoad, setFirstLoad] = useState(true);
    const [price, setPrice] = useState("");
    const [is_free, setIsFree] = useState(false);
    const [event_type, setEventType] = React.useState([]);
    const [is_online, setIsOnline] = React.useState(false);
    const [has_venue, setHasVenue] = React.useState(false);
    const [organiser_name, setOrganiserName] = useState("");
    const [organiser_website, setOrganiserWebsite] = useState("");
    const [online_event_link, setOnlineEventLink] = useState("");
    const [address, setAddress] = useState({area: "", city: "", country: "", address: ""});
    const [coordinates, setCoordinates] = useState({
        lat: 51.50853, lng: -0.12574
    });

    const history = useHistory();
    const [error, setError, errorRef] = useStateRef({}) as [any, any, any];

    useEffect(() => {
        if (categoryListData.length && categoryListData[0]?.value !== "select") {
            categoryListData.unshift({title: "Select Category", value: "select", disabled: true});
        }
        setCategories([...categoryListData]);
        let data = [...categoryListData];
        data.shift();
        setFilteredCategories(data);
        if (id) {
            setPageLoading(true);
            GetOneEvent(cookies?.token, id).then(response => {
                if (typeof profileDetails?.user_id !== "undefined" &&
                    profileDetails?.user_id !== response?.user_id) {
                    history.push("/");
                    /*If logged in user doesn't match with Post's User Id Redirect to
                     Buy-Sell Listing or show a static page like Post unavailable.*/
                }
                setInitialState(response).then(() => {
                });
            });
        }
        // eslint-disable-next-line
    }, []);

    const setInitialState = async (detailsData) => {
        const images = detailsData?.images || [];
        let imageList = [];
        if (images && images.length) {
            images.forEach(d => {
                imageList.push({
                    id: d.img_id,
                    src: d.sm,
                    apiName: d.rank,
                    name: "0" + d.rank,
                    d
                });
            });
            setImages(imageList);
        }
        const tags = detailsData?.categories.map(d => d.category_id);
        if (tags && tags.length) {
            categoryListData.pop();
            const initialTags = categoryListData.filter(d => tags.includes(d.value));
            setTagsName(initialTags as object[]);
            setTagsSize(initialTags.length as number);
        }
        setUserId(detailsData?.user_id);
        setCategory(detailsData?.primary_category?.category_id || "");
        setTitle(detailsData?.title || "");
        if (detailsData?.is_free) {
            setIsFree(true);
            setPrice("Free");
        } else {
            setIsFree(false);
            setPrice(detailsData?.price || "");
        }
        setDescription(detailsData?.description || "");
        setStartDateTime(detailsData?.start_date);
        setEndDateTime(detailsData?.end_date);

        setHasVenue(false);
        setIsOnline(false);
        if (detailsData?.is_online && detailsData?.has_venue) {
            setEventType(["ONLINE", "VENUE"]);
        } else if (detailsData?.is_online) {
            setEventType(["ONLINE"]);
        } else if (detailsData?.has_venue) {
            setEventType(["VENUE"]);
        }
        setIsOnline(detailsData?.is_online);
        setHasVenue(detailsData?.has_venue);
        setOnlineEventLink(detailsData?.online_event_link);
        setOrganiserName(detailsData?.organiser_name || "");
        setOrganiserWebsite(detailsData?.organiser_website || "");

        setAddress({
            area: detailsData?.area || "",
            city: detailsData?.city || "",
            country: detailsData?.country || "",
            address: detailsData?.address || ""
        });
        setCoordinates({
            lat: detailsData?.latitude || "",
            lng: detailsData?.longitude || ""
        });
        setPageLoading(false);
    };

    const handleTagChange = (e: ChangeEvent<{ value: unknown }>, value) => {
        setTagsName(value as object[]);
        setTagsSize(value.length as number);
        if (tagSize === MAX_TAGS_SIZE - 1) {
            setPopper("none");
        } else if (tagSize === MAX_TAGS_SIZE) {
            setPopper("block");
        }
    };

    const setPopper = value => {
        const popper = document.querySelector(".MuiAutocomplete-popper");
        if (popper) {
            //@ts-ignore
            popper.style.display = value;
        }
    };

    const handleDescriptionChange = (value: any) => {
        if (!stripHtml(value) && !firstLoad) {
            setError({...error, description: true});
        } else {
            setError({...error, description: false});
        }
        setFirstLoad(false);
        setDescription(value);
    };

    const handleCategoryChange = (e) => {
        const value = e.target.value;
        if (!value) {
            setError({...error, category: true});
        } else {
            setError({...error, category: false});
        }
        setCategory(value);
        let data = categoryListData.filter(d => d.value !== value);
        data.shift();
        setFilteredCategories(data);
    };

    const handleStartDateAndTimeChange = async (e) => {
        if (!e) {
            setError({...error, start_date_time: true});
        } else {
            setError({...error, start_date_time: false});
        }
        setStartDateTime(e);
    };

    const handleEndDateAndTimeChange = async (e) => {
        if (!e) {
            setError({...error, end_date_time: true});
        } else {
            setError({...error, end_date_time: false});
        }
        setEndDateTime(e);
    };

    const handleTitleChange = (e) => {
        if (!e.target.value) {
            setError({...error, title: true});
        } else {
            setError({...error, title: false});
        }
        setTitle(e.target.value);
    };

    const handlePriceChange = (e) => {
        if (!e.target.value) {
            setError({...error, price: true});
        } else {
            setError({...error, price: false});
        }
        let val = e.target.value;
        if (/^[\d]*\.?[\d]{0,2}$/.test(val)) {
            setPrice(val);
        }
    };

    const handleOrganiserName = e => {
        setOrganiserName(e.target.value);
    };

    const toggleSetAsFree = () => {
        setIsFree(!is_free);
        setPrice(!is_free ? "Free" : "");
    };

    const handleOrganiserWebsite = e => {
        setOrganiserWebsite(e.target.value);
    };

    const handleOnlineEventLinkChange = (e) => {
        const value = e?.target?.value;
        if (!value) {
            setError({...error, online_event_link: true});
        } else {
            setError({...error, online_event_link: false});
        }
        setOnlineEventLink(value);
    };

    const handleAddressChange = (address) => {
        setAddress(address);
    };

    const onDrop = useCallback(async (files) => {
        onDropCallback(files, imagesRef, setProgress, progressRef, setIsActive, setImages);
        // eslint-disable-next-line
    }, []);

    const handleDeleteImage = (id) => {
        if (id) {
            setImages(images.filter(d => d.id !== id));
        } else {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: "Image not found."
            });
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        let data: any = {
            user_id,
            title,
            merged_location: `${address?.city || null}, ${address?.country || null}`,
            city: address?.city || null,
            country: address?.country || null,
            area: address?.area || null,
            address: address?.address || null,
            latitude: address?.address ? coordinates?.lat : null,
            longitude: address?.address ? coordinates?.lng : null,
            category_id: category || null,
            description: description || null,
            is_free,
            is_online,
            has_venue,
            price: is_free ? null : price || null,
            start_date: moment(start_date_time).format("YYYY-MM-DD hh:mm:ss a"),
            end_date: moment(end_date_time).format("YYYY-MM-DD hh:mm:ss a"),
            online_event_link: online_event_link || null,
            organiser_name: organiser_name || null,
            organiser_website: organiser_website || null,
            categories: tagName.map((d: any) => d.value)
        };
        const validate = () => {
            let isValid = true;
            if (!data?.title) {
                setError({...errorRef.current, title: true});
                isValid = false;
            }
            if (!event_type.length) {
                setError({...errorRef.current, event_type: true});
                isValid = false;
            } else if (is_online && !online_event_link) {
                setError({...errorRef.current, online_event_link: true});
                isValid = false;
            } else if (has_venue && !address?.address) {
                setError({...errorRef.current, venue_event_link: true});
                isValid = false;
            }

            if (!data?.category_id) {
                setError({...errorRef.current, category: true});
                isValid = false;
            }

            if (!data?.start_date) {
                setError({...errorRef.current, start_date_time: true});
                isValid = false;
            }

            if (!stripHtml(data?.end_date)) {
                setError({...errorRef.current, end_date_time: true});
                isValid = false;
            }

            if (!stripHtml(data?.description)) {
                setError({...errorRef.current, description: true});
                isValid = false;
            }
            return isValid;
        };

        if (!validate()) {
            setLoading(false);
            setCommonErrorMessage("Mandatory fields missing in previous page. Go back to edit.");
            return;
        } else {
            setCommonErrorMessage("");
        }
        const event_id = id || "";
        try {
            if (id) {
                data = Object.assign({}, data, {
                    event_id,
                    images: imagesRef.current.filter(d => !d.file).map((d: any) => ({
                        img_id: d?.d?.img_id,
                        rank: d?.d?.rank
                    })),
                    user_id
                });
                UpdateEvents(cookies?.token, data).then((r) => {
                    const images = imagesRef.current.filter(d => d.file);
                    console.log(r);
                    if (images.length) {
                        uploadImages(cookies?.token, getImages(images, r)).then(d => {
                            console.log(d);
                            history.push(Routes.events);
                            /*if (d?.type === "success") {
                                CommonDispatcher(SHOW_SNACKBAR, {
                                    isShowing: true,
                                    message: "Post updated successfully."
                                });
                                redirectToPost(r?.response?.category_id || "", r?.response?.event_id || "");
                            } else {
                                CommonDispatcher(SHOW_SNACKBAR, {
                                    isShowing: true,
                                    message: "Failed to updated the post."
                                });
                            }*/
                            setLoading(false);
                        }).catch(() => setLoading(false));

                    } else {
                        history.push(Routes.events);
                        setLoading(false);
                        /* if (r?.type === "success") {
                             CommonDispatcher(SHOW_SNACKBAR, {
                                 isShowing: true,
                                 message: "Post updated successfully."
                             });
                             redirectToPost(r?.response?.category_id || "", r?.response?.event_id || "");
                         } else {
                             CommonDispatcher(SHOW_SNACKBAR, {
                                 isShowing: true,
                                 message: "Failed to update the post."
                             });
                         }*/
                    }
                }).catch(() => setLoading(false));
            }
        } catch (e) {
            setLoading(false);
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: "Something went wrong."
            });
        }
    };

    const getImages = (images, r) => {
        let formData = new FormData();
        images.forEach((image: any) => {
            formData.append("files", image.file as any, image.apiName);
        });
        formData.append("id", r?.response?.event_id || "");
        formData.append("type", "EVENTS");
        return formData;
    };

    const handleCancel = () => {
        history.push(Routes.events);
    };

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function getSteps() {
        return ["Describe the Event", "Publish Event"];
    }

    const handleSelectionChange = (event: React.MouseEvent<HTMLElement>, value: []) => {
        if (!value.length) {
            setError({...error, event_type: true});
        } else {
            setError({...error, event_type: false});
        }
        setEventType(value);
        setHasVenue(false);
        setIsOnline(false);
        value.forEach(eventType => {
            if (eventType === "VENUE") {
                setHasVenue(true);
            } else if (eventType === "ONLINE") {
                setIsOnline(true);
            }
        });
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <>
                    <Grid container className={`my-1 ${style.innerGridContainer}`}>
                        <Grid item xs={12} className="py-1">
                            <DragAndDrop onDrop={onDrop}
                                         accept={"image/png, image/jpg, image/jpeg"}
                                         images={images}
                                         isLoading={isActive}
                                         deleteImage={handleDeleteImage}
                                         maxFiles={10}
                                         label="Upload Photos (up to 10 Photos)"/>
                        </Grid>
                    </Grid>

                    <Grid container className={`my-1 ${style.innerGridContainer}`}>
                        <Grid item xs={12} className="py-1">
                            <RenderSelectField label='Category *'
                                               error={error.category}
                                               helperText={"Please select a category."}
                                               id="category"
                                               inputId="select-category"
                                               value={category}
                                               dropdownListHeight={250}
                                               onChange={handleCategoryChange}
                                               dataArray={categories}/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={`my-1 ${style.innerGridContainer}`}>
                        <Grid item xs={12} sm={6} className="py-1">
                            <RenderDateAndTimePicker id="start_date_time"
                                                     label="Start date and time *"
                                                     showInputLabel={true}
                                                     error={error.start_date_time}
                                                     helperText={"Please select start date and time."}
                                                     disablePast={true}
                                                     format={dateFormat}
                                                     value={start_date_time}
                                                     onChange={handleStartDateAndTimeChange}/>
                        </Grid>
                        <Grid item xs={12} sm={6} className="py-1">
                            <RenderDateAndTimePicker id="end_date_time"
                                                     label="End date and time *"
                                                     showInputLabel={true}
                                                     error={error.end_date_time}
                                                     helperText={"Please select end date and time."}
                                                     disablePast={true}
                                                     disabled={!start_date_time}
                                                     format={dateFormat}
                                                     value={end_date_time}
                                                     minDate={start_date_time}
                                                     onChange={handleEndDateAndTimeChange}/>
                        </Grid>
                    </Grid>

                    <Grid container className={`my-1 ${style.innerGridContainer}`}>
                        <Grid item xs={12} className="py-1">
                            <RenderTextField
                                error={error.title}
                                helperText={error.title ? "Title is mandatory" : null}
                                id="title"
                                value={title}
                                label="Event title *"
                                onChange={handleTitleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container className={`my-1 ${style.innerGridContainer}`}>
                        <Grid item xs={12} className="py-1">
                            <TextEditor
                                error={error.description}
                                placeholder="Enter your description here..."
                                initialValue={description}
                                label='Details about the event *'
                                handleDescriptionChange={handleDescriptionChange}
                                editorToolbarOptions={editorToolbarOptions}
                            />
                        </Grid>
                    </Grid>
                </>;
            case 1:
                return <>
                    <Grid container spacing={3} className={`my-1 ${style.innerGridContainer}`}>
                        <Grid item xs={12} md={6}>

                            <RenderMultiToggleSwitch id="event_type"
                                                     toggleButtonGroupId={"event_type"}
                                                     label="Select event types * (multiple selection)"
                                                     value={event_type}
                                                     error={error.event_type}
                                                     helperText={"Please select event type"}
                                                     onChange={handleSelectionChange}
                                                     dataArray={eventTypeList}/>

                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={`my-1 ${style.innerGridContainer}`}>
                        <Grid item xs={12} md={6} className="py-1">
                            <RenderTextField id="online_event_link"
                                             value={online_event_link}
                                             disabled={!is_online}
                                             error={is_online && !online_event_link}
                                             helperText={is_online && !online_event_link ? "Please enter event link" : ""}
                                             label="Set the online event link"
                                             type="website"
                                             onChange={handleOnlineEventLinkChange}/>
                        </Grid>

                        <Grid item xs={12} md={6} className="py-1">
                            <AutoCompleteAddressComponent id="venue_event_link"
                                                          coordinates={coordinates}
                                                          disabled={!has_venue}
                                                          defaultValue={address?.address || ""}
                                                          error={has_venue && !address?.address}
                                                          helperText={has_venue && !address?.address ? "Please enter venue location" : ""}
                                                          setCoordinates={setCoordinates}
                                                          setAddress={handleAddressChange}
                                                          autoComplete="off"
                                                          hideMap={true}
                                                          label="Set a venue location"/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={`my-1 ${style.innerGridContainer}`}>
                        <Grid item xs={12} md={6} className="py-1">
                            <InputLabel shrink htmlFor="price" className={style.inputLabels}>
                                Ticket Price
                            </InputLabel>
                            <div className={style.priceInputLayout}>
                                <Euro className={style.icon} component={"svg"}/>
                                <TextField id="price"
                                           type={is_free ? "text" : "number"}
                                           variant="outlined"
                                           value={price}
                                           defaultValue={price}
                                           inputProps={{
                                               disabled: is_free, className: `${is_free ? style.disabled : ""}`
                                           }}
                                           disabled={is_free}
                                           onChange={handlePriceChange}
                                           className={`${style.inputPriceFields}`}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} className="py-1 d-flex align-items-end">
                            <Button color={is_free ? "primary" : "secondary"} onClick={toggleSetAsFree}>
                                {is_free ? "Set Price" : "Set as Free"}
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={`my-1 ${style.innerGridContainer}`}>
                        <Grid item xs={12} md={6} className="py-1">
                            <RenderTextField id="organiser_name"
                                             value={organiser_name}
                                             label="Organiser Name"
                                             onChange={handleOrganiserName}/>
                        </Grid>

                        <Grid item xs={12} md={6} className="py-1">
                            <RenderTextField id="organiser_website"
                                             value={organiser_website}
                                             label="Organiser website"
                                             type="website"
                                             onChange={handleOrganiserWebsite}/>
                        </Grid>
                    </Grid>

                    <Grid container className={`my-1 ${style.innerGridContainer}`}>
                        <Grid item xs={12} className="py-1">
                            <RenderAutocompleteTextField id="tags"
                                                         value={tagName}
                                                         label="Add additional tags (maximum 2)"
                                                         options={tagSize < MAX_TAGS_SIZE ? filteredCategories : []}
                                                         onChange={handleTagChange}
                                                         inputPlaceholder="Search tags"/>
                        </Grid>
                    </Grid>
                </>;
        }
    }

    return <div className="pageContainer pt-5">
        <Container maxWidth="md">
            {isPageLoading ? <div className="mainLoader">
                    <div className="loader"/>
                </div> :
                <Grid item xs={12} md={8} sm={8} className={style.gridItem}>
                    <div className={style.form}>
                        <Grid container className={style.gridContainer}>
                            <div className="px-0 mb-5">
                                <Typography variant="h6" className={style.title}>
                                    Update your event
                                </Typography>
                            </div>

                            <Grid container className={`my-1 ${style.innerGridContainer}`}>
                                <Grid item xs={12} className="py-1">
                                    <Stepper activeStep={activeStep} alternativeLabel>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel StepIconProps={{classes: {completed: style.colorGreen}}}>
                                                    {label}
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                            </Grid>

                            <span className="text-center w-100 colorDanger">{commonErrorMessage}</span>

                            {getStepContent(activeStep)}

                            <WizardFooterActionButtons id={id}
                                                       isLoading={isLoading}
                                                       isActive={isActive}
                                                       isLastStep={activeStep === steps.length - 1}
                                                       submitTitle={activeStep === steps.length - 1 ? id ? "Update" : "Publish" : "Next"}
                                                       backButtonTitle="Go Back"
                                                       handleSubmit={handleSubmit}
                                                       handleNext={handleNext}
                                                       handleBack={handleBack}
                                                       handleCancel={handleCancel}/>
                        </Grid>
                    </div>
                </Grid>}
        </Container>
    </div>;
};

export default (connect(null,
    {
        uploadImages,
        GetOneEvent,
        UpdateEvents,
        CommonDispatcher
    })
(EditEvent));