import * as Actions from "./actionTypes";
import axios from "axios";
import {API_PATH} from "../../constants";

export const GetRealEstateList = (token: string, page_number: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/real-estate`,
                headers: {
                    Authorization: token
                },
                data: {page_number}
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_REAL_ESTATE_DATA,
                payload
            });
        } catch (e) {
            throw e;
        }
    };
};

export const DeleteRealEstate = (token: string, property_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {property_id},
                url: `${API_PATH}/admin/delete/real-estate`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const UpdateRealEstate = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/real-estate`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const VerifyRealEstate = (token: string, property_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {property_id},
                url: `${API_PATH}/admin/verify/real-estate`,
                headers: {Authorization: token}
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};