import * as Actions from "./actionTypes";
import axios from "axios";
import {API_PATH} from "../../constants";

export const GetOffersList = (token: string, page_number: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/offers`,
                headers: {
                    Authorization: token
                },
                data: {page_number}
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_OFFERS_DATA,
                payload
            });
        } catch (e) {
            throw e;
        }
    };
};

export const DeleteOffers = (token: string, offer_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {offer_id},
                url: `${API_PATH}/admin/delete/offers`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const UpdateOffers = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/offers`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const VerifyOffers = (token: string, offer_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {offer_id},
                url: `${API_PATH}/admin/verify/offers`,
                headers: {Authorization: token}
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};