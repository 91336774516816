import React, {useEffect} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {Home, People, Receipt} from "@material-ui/icons";
import BizServices from "../../resources/biz-services.svg";
import RealEstate from "../../resources/real-estate.svg";
import BuySell from "../../resources/buy-sell.svg";
import Stories from "../../resources/stories.svg";
import Offers from "../../resources/offers.svg";
import Events from "../../resources/events.svg";
import MeetPeople from "../../resources/meet_people_intro.webp";
import {Box,} from "@material-ui/core";
import {setSelectedTab} from "../../redux/Dashboard/actions";
import {useHistory} from "react-router-dom";
import {Routes} from "../../constants";
import {connect} from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: 300,
            backgroundColor: theme.palette.background.paper,
            borderRight: "1px solid rgb(235 235 235)"
        }
    })
);

function SelectedListItem(props: any) {
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        if (props?.selected_index) {
            handleListItemClick(props?.selected_index);
        }
        // eslint-disable-next-line
    }, [history?.location?.pathname]);

    const handleListItemClick = (index: number) => {
        props?.setSelectedTab(index);
        switch (index) {
            case -1:
                history.push(Routes.home);
                break;
            case 0:
                history.push(Routes.users);
                break;
            case 1:
                history.push(Routes.businessListings);
                break;
            case 2:
                history.push(Routes.realEstate);
                break;
            case 3:
                history.push(Routes.buySell);
                break;
            case 4:
                history.push(Routes.offers);
                break;
            case 5:
                history.push(Routes.events);
                break;
            case 6:
                history.push(Routes.stories);
                break;
            case 7:
                history.push(Routes.meetPeople);
                break;
            case 8:
                history.push(Routes.subscriptions);
                break;
            case 9:
                history.push(Routes.requestOffer);
                break;
        }
    };

    return <div className={classes.root}>
        <List className="py-0" component="nav" aria-label="main mailbox folders">
            <ListItem button
                      selected={history?.location?.pathname === Routes.home}
                      onClick={() => handleListItemClick(-1)}>
                <ListItemIcon>
                    <Home/>
                </ListItemIcon>
                <Box display={{xs: "none", sm: "block"}}>
                    <ListItemText primary="Home"/>
                </Box>
            </ListItem>
            <ListItem
                button
                selected={history?.location?.pathname === Routes.users}
                onClick={() => handleListItemClick(0)}
            >
                <ListItemIcon>
                    <People/>
                </ListItemIcon>
                <Box display={{xs: "none", sm: "block"}}>
                    <ListItemText primary="Users"/>
                </Box>
            </ListItem>

            <ListItem
                button
                selected={history?.location?.pathname === Routes.businessListings}
                onClick={() => handleListItemClick(1)}
            >
                <ListItemIcon>
                    <img src={BizServices} style={{maxWidth: "30px", maxHeight: "30px"}}
                         alt="Business & Services"/>
                </ListItemIcon>
                <Box display={{xs: "none", sm: "block"}}>
                    <ListItemText primary="Business Listings"/>
                </Box>
            </ListItem>

            <ListItem
                button
                selected={history?.location?.pathname === Routes.realEstate}
                onClick={() => handleListItemClick(2)}
            >
                <ListItemIcon>
                    <img src={RealEstate} style={{maxWidth: "30px", maxHeight: "30px"}} alt="Real Estate"/>
                </ListItemIcon>
                <Box display={{xs: "none", sm: "block"}}>
                    <ListItemText primary="Real Estate"/>
                </Box>
            </ListItem>

            <ListItem
                button
                selected={history?.location?.pathname === Routes.buySell}
                onClick={() => handleListItemClick(3)}
            >
                <ListItemIcon>
                    <img src={BuySell} style={{maxWidth: "30px", maxHeight: "30px"}} alt="Buy/Sell"/>
                </ListItemIcon>
                <Box display={{xs: "none", sm: "block"}}>
                    <ListItemText primary="Buy and Sell"/>
                </Box>
            </ListItem>

            <ListItem
                button
                selected={history?.location?.pathname === Routes.offers}
                onClick={() => handleListItemClick(4)}
            >
                <ListItemIcon>
                    <img src={Offers} style={{maxWidth: "30px", maxHeight: "30px"}} alt="Offers"/>
                </ListItemIcon>
                <Box display={{xs: "none", sm: "block"}}>
                    <ListItemText primary="Deals & Discounts"/>
                </Box>
            </ListItem>

            <ListItem button
                      selected={history?.location?.pathname === Routes.events}
                      onClick={() => handleListItemClick(5)}>
                <ListItemIcon>
                    <img src={Events} style={{maxWidth: "30px", maxHeight: "30px"}} alt="Events"/>
                </ListItemIcon>
                <Box display={{xs: "none", sm: "block"}}>
                    <ListItemText primary="Events"/>
                </Box>
            </ListItem>

            <ListItem
                button
                selected={history?.location?.pathname === Routes.stories}
                onClick={() => handleListItemClick(6)}
            >
                <ListItemIcon>
                    <img src={Stories} style={{maxWidth: "30px", maxHeight: "30px"}} alt="Stories"/>
                </ListItemIcon>
                <Box display={{xs: "none", sm: "block"}}>
                    <ListItemText primary="Stories"/>
                </Box>
            </ListItem>

            <ListItem button
                      selected={history?.location?.pathname === Routes.meetPeople}
                      onClick={() => handleListItemClick(7)}>
                <ListItemIcon>
                    <img src={MeetPeople} style={{maxWidth: "30px", maxHeight: "30px"}} alt="Meet People"/>
                </ListItemIcon>
                <Box display={{xs: "none", sm: "block"}}>
                    <ListItemText primary="Meet People"/>
                </Box>
            </ListItem>

            <ListItem button
                      selected={history?.location?.pathname === Routes.subscriptions}
                      onClick={() => handleListItemClick(8)}>
                <ListItemIcon>
                    <Receipt style={{maxWidth: "30px", maxHeight: "30px"}}/>
                </ListItemIcon>
                <Box display={{xs: "none", sm: "block"}}>
                    <ListItemText primary="Subscriptions"/>
                </Box>
            </ListItem>

            <ListItem button
                      selected={history?.location?.pathname === Routes.requestOffer}
                      onClick={() => handleListItemClick(9)}>
                <ListItemIcon>
                    <img src="https://luxexpats.fra1.cdn.digitaloceanspaces.com/request_offer.webp"
                         style={{maxWidth: "30px", maxHeight: "30px"}} alt="Request Offer"/>
                </ListItemIcon>
                <Box display={{xs: "none", sm: "block"}}>
                    <ListItemText primary="Request Offer"/>
                </Box>
            </ListItem>
        </List>
    </div>;
}

export default connect((state: any) => ({
    selected_index: state?.Dashboard?.selected_index
}), {setSelectedTab})(SelectedListItem);