import {applyMiddleware, combineReducers, createStore} from 'redux';
import Dashboard from './Dashboard';
import Users from './Users';
import BusinessListings from './BusinessListings';
import RealEstate from './RealEstate';
import BuySell from './BuySell';
import Offers from './Offers';
import Events from './Events';
import Stories from './Stories';
import MeetPeople from './MeetPeople';
import Subscriptions from './Subscriptions';
import thunk from 'redux-thunk';
import {ModalReducer} from "./ModalReducer";
import RequestOffer from "./RequestOffer";
import {composeWithDevTools} from "redux-devtools-extension";
import {CommonAppData} from "./Common";

const reducers = combineReducers({
    Dashboard,
    users: Users,
    businessListings: BusinessListings,
    realEstate: RealEstate,
    buySell: BuySell,
    events: Events,
    offers: Offers,
    stories: Stories,
    meetPeople: MeetPeople,
    subscriptions: Subscriptions,
    requestOffer: RequestOffer,
    modal: ModalReducer,
    commonAppData: CommonAppData,
});
/*const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default function configureStore() {
    const enhancer = composeEnhancers(applyMiddleware(thunk));
    return createStore(
        reducers,
        enhancer
    );
}*/
export const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(thunk))
)
export type RootState = ReturnType<typeof reducers>