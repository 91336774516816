import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle as MuiDialogTitle,
    Grid,
    IconButton,
    InputLabel,
    ListItem,
    ListItemText,
    makeStyles,
    TextField,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
    withStyles,
    WithStyles
} from "@material-ui/core";
import {connect, useSelector} from "react-redux";
import {CommonDispatcher, OpenModal} from "../../../redux/Common/action";
import {OPEN_REQUEST_OFFER_MODAL} from "../../../redux/ModalReducer/actionTypes";
import {Close, Euro} from "@material-ui/icons";
import {RootState} from "../../../redux/store";
import style from "./Style.module.scss";
import RenderToggleSwitch from "../../InputComponents/ToggleSwitch/RenderToggleSwitch";
import RenderTextField from "../../InputComponents/TextField/RenderTextField";
import useStateRef from "react-usestateref";
import RenderDateAndTimePicker from "../../InputComponents/DateAndTimePickerComponent/RenderDateAndTimePicker";
import moment from "moment";
import RenderSelectField from "../../InputComponents/SelectField/RenderSelectField";
import RenderAutocompleteTextField from "../../InputComponents/AutocompleteTextField";
import {GetRequestOfferList, UpdatePost} from "../../../redux/RequestOffer/actions";
import RenderMultiToggleSwitch from "../../InputComponents/MultiToggleSwitch/RenderMultiToggleSwitch";
import {SHOW_SNACKBAR} from "../../../redux/Common/actionTypes";
import {useCookies} from "react-cookie";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(() =>
    createStyles({
        dialogContainer: {maxWidth: "600px"},
        root: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                flex: "1 1 30%",
                margin: "auto"
            }
        }
    })
);

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        }
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    hidden: boolean;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {children, hidden, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle hidden={hidden} disableTypography className={`text-center ${classes.root}`} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Close/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

let postTypeList = [
    {title: "Request", value: "REQUEST", disabled: false},
    {title: "Offer", value: "OFFER", disabled: false}
];

let requestTypeList = [
    {title: "Online", value: "ONLINE", disabled: false},
    {title: "In-Person", value: "IN_PERSON", disabled: false}
];

let durationList = [
    {title: "Per Hour", value: "PER_HOUR", disabled: false},
    {title: "Per Day", value: "PER_DAY", disabled: false},
    {title: "Per Week", value: "PER_WEEK", disabled: false},
    {title: "Per Month", value: "PER_MONTH", disabled: false}
];
const RequestOfferModal = ({OpenModal, UpdatePost, GetRequestOfferList, CommonDispatcher}: any) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();
    const modal = useSelector((store: RootState) => store?.modal?.requestOfferModal) || {};
    const [post_type, setPostType] = React.useState(postTypeList[0]?.value);
    const [request_type, setRequestType] = React.useState([requestTypeList[1]?.value]);
    const [tagName, setTagsName] = useState<object[]>([]);
    const [tagSize, setTagsSize] = useState<number>(0);
    const [MAX_TAGS_SIZE] = useState<number>(modal?.data?.maxLimit || 2);
    const [error, setError, errorRef] = useStateRef({}) as [any, any, any];
    const [messageCharLimit] = React.useState(modal?.messageCharLimit || 400);
    const [messageCharCount, setMessageCharCount] = React.useState(0);
    const [message, setMessage] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [duration, setDuration] = React.useState(durationList[0]?.value);
    const [priceToBeDiscussed, setPriceToBeDiscussed] = React.useState(false);
    const [price, setPrice] = useState("Free");
    const [is_free, setIsFree] = useState(true);
    const [toggleDateTime, setToggleDateTime] = useState(false);
    const dateFormat = "DD MMMM YYYY hh:mm a";
    const [start_date_time, setStartDateTime]: any = useState(null);
    const [end_date_time, setEndDateTime]: any = useState(null);
    const tagsList = modal?.data?.categories || [];
    const is_premium = modal?.data?.is_premium;
    const post = modal?.data?.post
    const request_offer_id = post?.request_offer_id
    const [cookies] = useCookies(["token"]);
    const token = cookies["token"];
    const history = useHistory();

    useEffect(() => {
        if (request_offer_id) {
            setPostType(post?.type)
            let reqType = [];
            post?.is_online && reqType.push(requestTypeList[0]?.value)
            post?.is_in_person && reqType.push(requestTypeList[1]?.value)
            setRequestType(reqType)
            /*if (post?.categories && post?.categories.length) {
                const initialTags = tagsList.filter(d => post?.categories.includes(d.value));
                setTagsName(initialTags as object[]);
                setTagsSize(initialTags.length as number);
            } else*/
            if (post?.category_id) {
                const initialTags = tagsList.find(d => parseInt(post?.category_id) === d.value);
                setTagsName([initialTags] as object[]);
                setTagsSize([initialTags].length as number);
            }

            setMessage(post?.description)
            if (!post?.start_date) {
                setToggleDateTime(false)
                setStartDateTime(null)
                setEndDateTime(null)
            } else {
                setToggleDateTime(true)
                setStartDateTime(moment.utc(post?.start_date))
                setEndDateTime(moment.utc(post?.end_date))
            }
            if (post?.pay_rate) {
                setIsFree(false);
                setPrice(post?.pay || "");
                setDuration(post?.pay_rate || durationList[0]?.value);
            } else if (!post?.is_free && !post?.pay_rate) {
                setPriceToBeDiscussed(true)
                setIsFree(post?.is_free);
                setPrice("")
            } else {
                setIsFree(true);
                setPrice("Free")
            }
        }
    }, [])
    const handleClose = () => {
        OpenModal(OPEN_REQUEST_OFFER_MODAL, {isOpen: false});
    };

    const handlePostTypeChange = (e, value) => {
        if (value !== null) {
            setPostType(value);
        }
    };

    const handleRequestTypeChange = (event: React.MouseEvent<HTMLElement>, value: []) => {
        if (value && value.length) {
            setRequestType(value);
        }
    };

    const handleTagChange = (e: ChangeEvent<{ value: unknown }>, value) => {
        setTagsName(value as object[]);
        setTagsSize(value.length as number);
        if (tagSize === MAX_TAGS_SIZE - 1) {
            setPopper("none");
        } else if (tagSize === MAX_TAGS_SIZE) {
            setPopper("block");
        }
    };

    const setPopper = value => {
        const popper = document.querySelector(".MuiAutocomplete-popper");
        if (popper) {
            //@ts-ignore
            popper.style.display = value;
        }
    };

    /* const handleSelectionChange = (event: React.MouseEvent<HTMLElement>, value: []) => {
         if (selectedTags.length < modal?.data?.maxLimit || value.length < modal?.data?.maxLimit) {
             setSelectedTags(value);
         }
     };*/

    const limit = messageCharLimit - messageCharCount;

    const handleChange = (e) => {
        let value = e?.target?.value;
        value = value.substr(0, messageCharLimit);
        setMessageCharCount(value.length);
        setMessage(value);
        if (value && value.length === messageCharLimit) {
            return;
        }
    };

    const handleStartDateAndTimeChange = async (e) => {
        if (!e) {
            setError({...error, start_date_time: true});
        } else {
            setError({...error, start_date_time: false});
        }
        setStartDateTime(e);
        setEndDateTime(moment(e).add(1, 'hours'));
    };

    const handleEndDateAndTimeChange = async (e) => {
        if (!e) {
            setError({...error, end_date_time: true});
        } else {
            setError({...error, end_date_time: false});
        }
        setEndDateTime(e);
    };

    const handleDurationChange = async (e) => {
        setDuration(e?.target?.value);
    };

    const handlePriceChange = (e) => {
        if (!e.target.value) {
            setError({...error, price: true});
        } else {
            setError({...error, price: false});
        }
        let val = e.target.value;
        if (/^[\d]*\.?[\d]{0,2}$/.test(val)) {
            setPrice(val);
        }
    };

    const handleToggleDateTime = () => {
        setToggleDateTime(!toggleDateTime)
    }

    const handlePriceToBeDiscussed = () => {
        if (priceToBeDiscussed) {
            setPriceToBeDiscussed(false)
            setIsFree(true);
            setPrice("Free");
        } else {
            setPriceToBeDiscussed(true)
            setPrice("");
        }
    }

    const toggleSetAsFree = is_free => {
        setIsFree(!is_free);
        setPrice(!is_free ? "Free" : "");
    };

    const handleSubmit = () => {
        const start_date = toggleDateTime ? start_date_time ? moment(start_date_time).format('YYYY-MM-DDTHH:mm:ss') : null : null;
        const end_date = toggleDateTime ? end_date_time ? moment(end_date_time).format('YYYY-MM-DDTHH:mm:ss') : null : null;
        let categories = [], category_id;
        if (tagName && tagName.length) {
            categories = tagName.map((d: any) => d.value);
            category_id = categories[0];
        }
        const type = post_type;
        let is_in_person = false, is_online = false;
        if (request_type && request_type.length) {
            is_in_person = request_type.includes("IN_PERSON")
            is_online = request_type.includes("ONLINE")
        }
        const description = message;
        const pay = priceToBeDiscussed ? null : !is_free ? price : null;
        const pay_rate = priceToBeDiscussed ? null : !is_free ? duration : null;

        let data: any = {
            description,
            type,
            is_in_person,
            is_online,
            category_id,
            categories,
            start_date,
            end_date,
            pay,
            pay_rate,
            is_free: priceToBeDiscussed ? false : is_free
        }
        const validate = () => {

            let isValid = true;

            if (!data?.category_id) {
                setError({...errorRef.current, selectedTags: true});
                isValid = false;
            }

            if (!data?.description) {
                setError({...errorRef.current, message: true});
                isValid = false;
            }

            return isValid;
        };

        if (!validate()) {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: "Please complete the missing fields",
            });
            return;
        }

        setIsLoading(true)
        if (request_offer_id) {
            data.request_offer_id = request_offer_id;
            UpdatePost(token, data).then(() => {
                const index = history?.location?.search.indexOf("=");
                const pageNumber = parseInt(history?.location?.search.substr(index + 1));
                GetRequestOfferList(token, pageNumber).then(() => {
                    setIsLoading(false);
                    handleClose();
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: "Post updated successfully",
                    });
                });
            })
        }
    };

    return <Dialog fullScreen={fullScreen}
                   maxWidth={modal?.config?.modalSize || "sm"}
                   fullWidth={modal?.config?.fullWidth || true}
                   open={modal?.isOpen || false}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description"
                   disableBackdropClick={modal?.config?.disableBackdropClick || false}
                   classes={{paperScrollPaper: classes.dialogContainer}}
                   onClose={handleClose}>
        <DialogTitle id="alert-dialog-title" hidden={modal?.config?.hideTitle || false} onClose={handleClose}>
            {modal?.title}
        </DialogTitle>
        <DialogContent className={style.dialog}>
            <DialogContentText id="alert-dialog-description">
                {modal?.contentTitle ? <div className="w-100 mb-5 text-left colorPrimary">
                    <span className="fs-16">{modal?.contentTitle}</span>
                </div> : null}

                <Container maxWidth="md">
                    <Grid container spacing={3} className={`my-1`}>
                        <Grid item xs={12} sm={6} className="py-1">
                            <RenderToggleSwitch id="type"
                                                label='Post Type'
                                                value={post_type}
                                                buttonClassName='w-100'
                                                onChange={handlePostTypeChange}
                                                dataArray={postTypeList}/>
                        </Grid>

                        <Grid item xs={12} sm={6} className="py-1">
                            <RenderMultiToggleSwitch id="type"
                                                     label='to be done'
                                                     toggleButtonGroupId={"event_type"}
                                                     value={request_type}
                                                     buttonClassName='w-100'
                                                     onChange={handleRequestTypeChange}
                                                     dataArray={requestTypeList}/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={`my-1`}>
                        <Grid item xs={12} className="py-1">
                            <RenderAutocompleteTextField id="selectedTags"
                                                         value={tagName}
                                                         error={error.selectedTags}
                                                         helperText={error.selectedTags && "Please select a tag"}
                                                         label={
                                                             <>
                                                                 Add a tag to help people find your post * <span
                                                                 className={`${style.limit} colorGray`}>
                                {/*(maximum {MAX_TAGS_SIZE})*/}
                                </span>
                                                             </>}
                                                         options={tagSize < MAX_TAGS_SIZE ? tagsList : []}
                                                         onChange={handleTagChange}
                                                         inputPlaceholder="Search tags"/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={`my-1`}>
                        <Grid item xs={12} className="py-1">
                            <p className={`my-0 float-right fs-14 ${style.limit}`}>
                                {limit} words *
                            </p>
                            <RenderTextField id="title"
                                             rows={5}
                                             max={messageCharLimit}
                                             value={message}
                                             multiline={true}
                                             error={error.message}
                                             helperText={error.message && "Please describe your request or offer."}
                                             onChange={handleChange}
                                             onKeyPress={handleChange}
                                             inputStyle="p-0"
                                             autoFocus={true}
                                             placeholder="Describe your request or offer *"/>
                        </Grid>
                    </Grid>

                    {!toggleDateTime ? <Grid container spacing={3} className="mt-5 mb-2">
                            <Grid item xs={12} className="py-1 d-flex flex-direction-column">
                                <span className="text-uppercase colorBlue fs-16 cursor-pointer"
                                      onClick={handleToggleDateTime}>
                                    Set a specific available time
                                    </span>
                                <span className={`my-0 fs-14 colorPrimary ${style.limit} colorGray`}>
                                    Optionally you can add a specific available time. Your post will automatically expire after the end time.
                                    </span>
                            </Grid>
                        </Grid>
                        :
                        <div className="mt-5 mb-2">
                             <span className="text-uppercase colorBlue fs-16 cursor-pointer"
                                   onClick={handleToggleDateTime}>
                                 Hide time
                                 </span>
                            <Grid container spacing={3} className={`mt-0`}>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <RenderDateAndTimePicker id="start_date_time"
                                                             label="Start date and time"
                                                             value={start_date_time}
                                                             showInputLabel={true}
                                                             error={error.start_date_time}
                                                             helperText={"Please select start date and time."}
                                                             disablePast={true}
                                                             format={dateFormat}
                                                             defaultValue={start_date_time}
                                                             onChange={handleStartDateAndTimeChange}/>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <RenderDateAndTimePicker id="end_date_time"
                                                             label="End date and time"
                                                             value={end_date_time}
                                                             showInputLabel={true}
                                                             error={error.end_date_time}
                                                             helperText={"Please select end date and time."}
                                                             disablePast={true}
                                                             disabled={!start_date_time}
                                                             format={dateFormat}
                                                             defaultValue={end_date_time}
                                                             minDate={start_date_time}
                                                             onChange={handleEndDateAndTimeChange}/>
                                </Grid>
                            </Grid>
                        </div>}

                    <Grid container spacing={3} className="my-0">
                        <Grid item xs={12} sm={6} className="py-0">
                            <ListItem className="p-0">
                                <ListItemText className="colorPrimary" primary={"Price to be discussed"}/>
                                <Checkbox edge="end"
                                          color="primary"
                                          checked={priceToBeDiscussed}
                                          onChange={handlePriceToBeDiscussed}/>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className="my-1">
                        <Grid item xs={12} sm={6} className="py-1">
                            <div className="d-flex align-items-center justify-content-between">
                                <InputLabel htmlFor="price" className={style.inputLabels}>
                                    Set a Price
                                </InputLabel>
                                <Button color={is_free ? "primary" : "secondary"} className="py-0"
                                        onClick={() => toggleSetAsFree(is_free)}>
                                    {is_free ? "Set Price" : "Set as Free"}
                                </Button>
                            </div>
                            <div className={style.priceInputLayout}>
                                <Euro className={style.icon} component={"svg"}/>
                                <TextField id="price"
                                           type={is_free ? "text" : "number"}
                                           variant="outlined"
                                           value={price}
                                           defaultValue={price}
                                           inputProps={{
                                               disabled: priceToBeDiscussed || is_free,
                                               className: `${priceToBeDiscussed || is_free ? style.disabled : ""}`
                                           }}
                                           disabled={is_free}
                                           onChange={handlePriceChange}
                                           className={`${style.inputPriceFields}`}/>
                            </div>
                        </Grid>
                        {!is_free ? <Grid item xs={12} sm={6} className="py-1">
                            <RenderSelectField id="duration"
                                               label='Duration'
                                               value={duration}
                                               error={error.duration}
                                               disabled={priceToBeDiscussed}
                                               helperText={error.duration && "Please select duration."}
                                               inputContainerClassName="w-100"
                                               onChange={handleDurationChange}
                                               inputId="select-duration"
                                               dropdownListHeight={250}
                                               dataArray={durationList}/>
                        </Grid> : null}
                    </Grid>
                </Container>
            </DialogContentText>
        </DialogContent>
        <DialogActions className="justify-content-center">
            <Button color="primary"
                    variant="contained"
                    className="mr-2 my-2 d-flex align-items-center justify-content-center"
                    onClick={handleSubmit}>
                {request_offer_id ? 'Update Post' : "Publish Post"}
                {isLoading ?
                    <CircularProgress size="1.3rem" className="circular-progress"/> : null}
            </Button>
        </DialogActions>
    </Dialog>;
};

export default connect(null, {
    OpenModal,
    UpdatePost,
    GetRequestOfferList,
    CommonDispatcher
})(RequestOfferModal);