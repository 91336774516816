import React, {useEffect, useState} from 'react';
import {useCookies} from "react-cookie";
import {
    fetchOneClassifieds,
    fetchOneOfferData,
    fetchOneRealEstate,
    fetchOneSellData,
    fetchOneStoryData,
    fetchUserProfile
} from "../../redux/Dashboard/actions";
import {useLocation} from 'react-router-dom';
import Tuple from "./Tuple";
import {connect} from "react-redux";
import {GetOneEvent} from "../../redux/Events/actions";
import {Routes} from "../../constants";

function DetailPage(props) {
    const [cookies] = useCookies(['token']);
    const location = useLocation();
    const [data, setData] = useState({} as any);
    useEffect(() => {
        let promise = Promise.resolve({});
        switch (location?.pathname?.split('/')?.[1]) {
            case Routes.users?.split('/')?.[1]:
                promise = props?.fetchUserProfile(cookies?.token, props?.match?.params?.id);
                break;
            case Routes.businessListings?.split('/')?.[1]:
                promise = props?.fetchClassifieds(cookies?.token, props?.match?.params?.id);
                break;
            case Routes.realEstate?.split('/')?.[1]:
                promise = props?.fetchOneRealEstate(cookies?.token, props?.match?.params?.id);
                break;
            case Routes.buySell?.split('/')?.[1]:
                promise = props?.fetchOneSellData(cookies?.token, props?.match?.params?.id);
                break;
            case Routes.offers?.split('/')?.[1]:
                promise = props?.fetchOneOfferData(cookies?.token, props?.match?.params?.id);
                break;
            case Routes.events?.split('/')?.[1]:
                promise = props?.GetOneEvent(cookies?.token, props?.match?.params?.id);
                break;
            case Routes.stories?.split('/')?.[1]:
                promise = props?.fetchOneStoryData(cookies?.token, props?.match?.params?.id);
                break;
        }
        promise.then(setData);
        // eslint-disable-next-line
    }, [props?.match?.params?.id, cookies]);
    const makeLabel = (key: string) => key.split('_').join(' ')
        .split('-').join(' ')
        .replace(/^\w/, c => c.toUpperCase());
    return (
        <div className="my-4 mx-0">
            {Object.keys(data).map((key: string, i) => {
                if (typeof data[key] === 'string')
                    return <Tuple label={makeLabel(key)} key={i} value={data[key]}/>
                else
                    return null;
            })}
        </div>
    );
}

export default connect(null, {
    fetchUserProfile,
    fetchClassifieds: fetchOneClassifieds,
    fetchOneRealEstate,
    fetchOneSellData,
    fetchOneOfferData,
    fetchOneStoryData,
    GetOneEvent
})(DetailPage);
