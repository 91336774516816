import * as Actions from "./actionTypes";
import axios from "axios";
import {API_PATH} from "../../constants";

export const GetBusinessListingsList = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/biz-services`,
                headers: {
                    Authorization: token
                },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_BUSINESS_LISTINGS_DATA,
                payload
            });
        } catch (e) {
            throw e;
        }
    };
};

export const DeleteBusinessListings = (token: string, service_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {service_id},
                url: `${API_PATH}/admin/delete/biz-services`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const UpdateBusinessListings = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/biz-services`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            return {type: "failed"}
        }
    };
};

export const VerifyBusinessListings = (token: string, service_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {service_id},
                url: `${API_PATH}/admin/verify/biz-services`,
                headers: {Authorization: token}
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};