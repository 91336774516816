import * as ACTIONS from './actionTypes';

const initialValues = {
    tableHeader: [
        {id: 'actions', label: 'Actions'},
        {id: 'createdAt', label: 'Join Date'},
        {id: 'type', label: 'Account Type'},
        {id: 'full_name', label: 'Name'},
        {id: 'email', label: 'Email'},
        {id: 'status', label: 'Email Status'},
        {id: 'actionStatus', label: 'Post Action'},
    ],
    listingsData: {
        current_page: 0,
        total_pages: 0,
        posts: [],
        count: 0
    }
}

const UsersReducer = (state = initialValues, action: any) => {
    switch (action.type) {
        case ACTIONS.SET_USERS_DATA:
            return {
                ...state,
                listingsData: {
                    posts: action?.payload?.users,
                    count: action?.payload?.count,
                    current_page: action?.payload?.page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                }
            };
        default:
            return state;
    }
};

export default UsersReducer;