import React, {useEffect} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {useCookies} from "react-cookie";
import {useHistory, useLocation} from "react-router-dom";
import {ArrowBack} from "@material-ui/icons";
import {grey} from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import {Routes} from "../../constants";
import {connect} from "react-redux";
import {OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL} from "../../redux/ModalReducer/actionTypes";
import {CreateSubscription} from "../../redux/Subscriptions/actions";
import {CommonDispatcher, OpenModal} from "../../redux/Common/action";
import {SHOW_SNACKBAR} from "../../redux/Common/actionTypes";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            flexGrow: 1,
            display: "none",
            [theme.breakpoints.up("sm")]: {
                display: "block"
            }
        }
    })
);

const Header = ({OpenModal, CommonDispatcher, CreateSubscription}) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const token = cookies["token"];

    useEffect(() => {
        renderPageName();
        // eslint-disable-next-line
    }, []);

    const doLogout = () => {
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        setCookie("token", "", {path: "/", expires: oneYearFromNow});
        removeCookie("token", {path: "/"});
        alert("Logged out! Bye.");
    };

    const renderPageName = () => {
        let title = "";
        switch (location.pathname) {
            case Routes.home:
                title = "Home";
                break;
            case Routes.users:
                title = "Users";
                break;
            case Routes.businessListings:
                title = "Business Listings";
                break;
            case Routes.realEstate:
                title = "Real Estate";
                break;
            case Routes.buySell:
                title = "Buy Sell";
                break;
            case Routes.offers:
                title = "Offers";
                break;
            case Routes.events:
                title = "Events";
                break;
            case Routes.stories:
                title = "Stories";
                break;
            case Routes.meetPeople:
                title = "Meet People";
                break;
            case Routes.requestOffer:
                title = "Request Offer";
                break;
            case Routes.subscriptions:
                title = "Subscriptions";
                break;
        }
        return title;
    };

    const showBackButton = () => {
        const routes = [
            Routes.home,
            Routes.users,
            Routes.businessListings,
            Routes.realEstate,
            Routes.buySell,
            Routes.offers,
            Routes.events,
            Routes.stories,
            Routes.meetPeople,
            Routes.requestOffer,
            Routes.subscriptions
        ];
        return !routes.includes(location.pathname);
    };

    const handleActivateSubscription = () => {
        OpenModal(OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL, {
            isOpen: true,
            title: "Create Premium Subscription",
            submitTitle: "Create",
            data: {modalSize: 'xs'},
            callBack: (flag, data) => {
                if (flag) {
                    console.log(data)
                    CreateSubscription(token, data).then(() => {
                        OpenModal(OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL, {isOpen: false, isLoading: false});
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: "Subscription created successfully"
                        });
                    })
                }
            }
        })
    }

    return <AppBar position="fixed">
        <Toolbar>
            {showBackButton() ?
                <IconButton onClick={() => history.goBack()} className="p-0">
                    <ArrowBack style={{color: grey[100]}}/>
                </IconButton> : null}
            <Typography className={classes.title} variant="h6" noWrap>
                {renderPageName()}
            </Typography>
            <Button color="inherit" onClick={handleActivateSubscription}>Activate Subscription</Button>
            <Button color="inherit" onClick={doLogout}>Logout</Button>
        </Toolbar>
    </AppBar>;
};

export default connect(null, {CreateSubscription, CommonDispatcher, OpenModal})(Header);