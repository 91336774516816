import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import {GetMeetPeopleList} from "../../redux/MeetPeople/actions";
import {UpdateUserProfile} from "../../redux/Users/actions";
import {connect, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";
import {makeStyles} from "@material-ui/core/styles";
import {RootState} from "../../redux/store";
import {Chip, Table} from "@material-ui/core";
import {useCookies} from "react-cookie";
import {useHistory} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {Routes} from "../../constants";
import {ENV} from "../../utils/constants";
import style from './Styles.module.scss'
import TableHeaderFilter from "../Common/TableHeaderFilter";

const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "100%"
    },
    container: {
        maxHeight: "calc( 100vh - 220px)",
        minHeight: "calc( 100vh - 220px)"
    }
});

const options = [
    {title: "Profile", value: "profile"},
    {title: "Meet People Profile", value: "meet_people_profile"}
];

const ITEM_HEIGHT = 48;
const MeetPeople = ({GetMeetPeopleList, UpdateUserProfile}) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const tableHeader = useSelector((store: RootState) => store?.meetPeople.tableHeader || []);
    const listingsData = useSelector((store: RootState) => store?.meetPeople.listingsData);
    const categories = useSelector((store: RootState) => store?.commonAppData?.meetPeople) || [];
    const [page, setPage] = useState((listingsData?.current_page || 1) - 1);
    const rowsPerPage = 30;
    const history = useHistory();
    const [cookies] = useCookies(["token"]);
    const token = cookies["token"];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>, user) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    useEffect(() => {
        const index = history?.location?.search.indexOf("=");
        const query = new URLSearchParams(history?.location?.search);
        const keyword = query.get('keyword');
        if (keyword) {
            getData({keyword});
        } else {
            const pageNo = parseInt(history?.location?.search.substr(index + 1));
            if (pageNo) {
                getData({page_number: pageNo});
                setPage(pageNo - 1);
            } else {
                getData({page_number: 1});
            }
        }
        // eslint-disable-next-line
    }, [history?.location?.search]);

    const getData = (data) => {
        setIsLoading(true);
        GetMeetPeopleList(token, data).then(() => {
            setIsLoading(false);
        });
    };

    const appendPageNumber = (pageNumber) => {
        history.push({
            pathname: Routes.meetPeople,
            search: `?page=${pageNumber}`
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        appendPageNumber(newPage + 1);
    };

    const handleChange = (value, selectedUser) => {
        switch (value) {
            case "profile":
                if (selectedUser?.user_name) {
                    const url = ENV === "DEVELOPMENT" ? `https://www.timexyz.com/${selectedUser?.user_name}` :
                        `https://www.luxembourgexpats.lu/${selectedUser?.user_name}`;
                    window.open(url, "_blank");
                } else {
                    alert("User details not available.")
                }
                break;
            case "meet_people_profile": {
                if (selectedUser?.user_id) {
                    const url = ENV === "DEVELOPMENT" ? `https://www.timexyz.com${Routes.meetPeople}/post/${selectedUser?.user_id}` :
                        `https://www.luxembourgexpats.lu${Routes.meetPeople}/post/${selectedUser?.user_id}`;
                    window.open(url, "_blank");
                } else {
                    alert("User details not available.")
                }
                break;
            }
        }
    };

    const renderMenu = ({user_id, user_name}) => {
        return <>
            <IconButton aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={e => handleClick(e, {user_id, user_name})}>
                <MoreVertIcon/>
            </IconButton>
            <Menu id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  classes={{paper: `${style.menu} border2px w-auto`}}
                  PaperProps={{
                      style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "20ch"
                      }
                  }}>
                {options.map((option, i) => (
                    <MenuItem key={option?.value}
                              onClick={() => {
                                  handleChange(option?.value, selectedUser);
                                  setAnchorEl(null);
                              }}>
                        {option?.title}
                    </MenuItem>
                ))}
            </Menu>
        </>;
    };

    const handleAvailability = (user_id, available_to_meet) => {
        UpdateUserProfile(token, {
            user_id,
            available_to_meet: !available_to_meet
        }).then(r => {
            if (r?.type === 'success') {
                alert("Update successful!");
                getData({page_number: page})
            } else {
                alert("Update failed!");
            }
        })
    }

    return <Grid container spacing={0} className="h-100">
        <Grid item xs={12}>
            <Paper className={classes.root}>
                <TableHeaderFilter sectionBaseUrl={Routes.meetPeople}/>
                {isLoading ? <div className="mainLoader">
                    <div className="loader"/>
                </div> : <>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {tableHeader?.map((column) => (
                                        <TableCell key={column.id}>
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listingsData?.posts.length ? listingsData?.posts?.map(row => {
                                        const color = ["LIVE", "VERIFIED"].includes(row.status) ? "#319401" : "#ff0000";
                                        const interests = row?.interests && row?.interests.length ? row?.interests.map(d => d.interest_id) : []
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row?.user_id}
                                                      style={{padding: 0, cursor: "pointer"}}
                                                      onClick={(e) => {
                                                          // history.push(`${Routes.meetPeople}/${row?.user_id}`);
                                                      }}>
                                                <TableCell size={"small"} onClick={e => e.stopPropagation()}>
                                                    {renderMenu(row)}
                                                </TableCell>
                                                <TableCell style={{color, minWidth: "120px"}} size={"small"}>
                                                    {row?.meet_people_access_date ? moment(row?.meet_people_access_date).format("DD MMM, YYYY") : 'N/A'}
                                                </TableCell>
                                                <TableCell style={{color}} size={"small"}>
                                                    {row?.status}
                                                </TableCell>
                                                <TableCell style={{color}} size={"small"}
                                                           onClick={e => e.stopPropagation()}>
                                                    <Chip size="small"
                                                          label={row?.status === "LIVE" ? "Deactivate" : "Activate"}
                                                          clickable
                                                          color={row?.status === "LIVE" ? "secondary" : "primary"}/>
                                                </TableCell>
                                                <TableCell style={{color}} size={"small"}>
                                                    {row?.type}
                                                </TableCell>
                                                <TableCell style={{color}} size={"small"}>
                                                    <Chip size="small"
                                                          clickable
                                                          label={row?.is_premium ? "YES" : "NO"}
                                                          color={row?.is_premium ? "primary" : "secondary"}/>
                                                </TableCell>
                                                <TableCell style={{color}} size={"small"}>
                                                    {row?.full_name}
                                                </TableCell>
                                                <TableCell style={{color}} size={"small"}>
                                                    {row?.email}
                                                </TableCell>
                                                <TableCell style={{color}} size={"small"}>
                                                    {row?.gender}
                                                </TableCell>
                                                <TableCell style={{color}} size={"small"}>
                                                    {row?.created_with}
                                                </TableCell>
                                                <TableCell style={{color}} size={"small"}
                                                           onClick={e => e.stopPropagation()}>
                                                    <Chip size="small"
                                                          clickable
                                                          label={row?.available_to_meet ? "YES" : "NO"}
                                                          color={row?.available_to_meet ? "primary" : "secondary"}
                                                          onClick={() => {
                                                              handleAvailability(row?.user_id, row?.available_to_meet);
                                                          }}/>
                                                </TableCell>
                                                <TableCell style={{color}}>
                                                    {row?.interests && row?.interests.length ?
                                                        categories.filter(c => interests.includes(c?.value)).map((d, i) => {
                                                            return <span className="colorBlue" key={i}>
                                                                    {i !== interests?.length - 1 ? `${d?.title}, ` : d?.title}
                                                                </span>
                                                        })

                                                        /* <RenderMultiToggleSwitch id="selectedInterests"
                                                                                    labelClassName={style.labelClassName}
                                                                                    value={interests}
                                                                                    checkedClassName={style.checked}
                                                                                    buttonGroupClassName={`d-flex align-items-center
                                                                                  justify-content-md-center ${style.buttonGroupClassName}`}
                                                                                    buttonClassName={style.buttonClassName}
                                                                                    dataArray={categories.filter(c => interests.includes(c?.value))}/>*/
                                                        : 'N/A'}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) :
                                    <tr>
                                        <td colSpan={tableHeader.length + 1} className="dataNotAvailable">
                                            Data not available
                                        </td>
                                    </tr>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination hidden={!listingsData?.posts.length}
                                     rowsPerPageOptions={[]}
                                     component="div"
                                     count={listingsData?.count}
                                     rowsPerPage={rowsPerPage}
                                     page={page}
                                     onChangePage={handleChangePage}/>
                </>}
            </Paper>
        </Grid>
    </Grid>;
};
export default connect(null, {
    GetMeetPeopleList,
    UpdateUserProfile
})(MeetPeople);