import React, { useCallback, useState } from "react";
import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { CheckUserExists, CommonDispatcher, OpenModal } from "../../../redux/Common/action";
import { RootState } from "../../../redux/store";
import { TOGGLE_CHANGE_OWNERSHIP_MODAL } from "../../../redux/ModalReducer/actionTypes";
import { Controller, useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import { useCookies } from "react-cookie";
import { SHOW_SNACKBAR } from "../../../redux/Common/actionTypes";

const ChangeOwnershipModal = ({OpenModal, CommonDispatcher, CheckUserExists}: any) => {
    const modal = useSelector((store: RootState) => store?.modal?.changeOwnershipModal) || {};
    const [isCheckUserLoading, setIsCheckUserLoading] = useState(false);
    const initialState = {};
    const [ownerData, setOwnerData] = useState<any>(initialState);
    const [cookies] = useCookies(["token"]);
    const token = cookies["token"];
    const {handleSubmit, formState: {errors}, control} = useForm();
    const delayedQuery = useCallback(_.debounce((k) => handleSearch(k), 500), []);

    const doSearch = (e: any) => {
        const value = e.target.value;
        if (value) {
            delayedQuery(value);
            setIsCheckUserLoading(true);
        } else {
            delayedQuery.cancel();
            setIsCheckUserLoading(false);
        }
    };

    const handleSearch = (email) => {
        CheckUserExists(token, {email}).then((d) => {
            setIsCheckUserLoading(false);
            if (d?.type === "success") {
                setOwnerData(d?.response);
            } else if (d?.type === "failed") {
                setOwnerData(initialState);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: "User not found"
                });
            }
        });
    };

    const handleClose = () => {
        OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {isOpen: false});
    };

    const handleChangeOwner = async () => {
        if (typeof modal?.callBack !== "undefined" && typeof modal?.callBack === "function") {
            modal?.callBack(true, ownerData?.user_id);
        }
    };

    return <Dialog maxWidth={modal?.data?.modalSize || "sm"}
                   fullWidth={modal?.data?.fullWidth || true}
                   onClose={handleClose}
                   open={modal?.isOpen || false}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description"
                   disableBackdropClick={modal?.data?.disableBackdropClick || false}>
        <DialogTitle id="alert-dialog-title">{modal?.title}</DialogTitle>
        <form onSubmit={handleSubmit(handleChangeOwner)} noValidate>
            <DialogContent className="mb-3 pt-0 px-5 overflow-unset">
                <Controller name="currentOwnerEmail"
                            as={
                                <TextField variant="outlined"
                                           margin="normal"
                                           required
                                           fullWidth
                                           id="currentOwnerEmail"
                                           label="Current owner email address"
                                           name="email"
                                           placeholder="current@email.com"
                                           autoComplete="off"/>
                            }
                            control={control}
                            defaultValue={modal?.data?.owner_email}
                            error={!!errors?.currentOwnerEmail}/>
                <FormControl variant="outlined" className="w-100 mt-3">
                    <InputLabel htmlFor="newOwnerEmail">New owner email address</InputLabel>
                    <Controller name="newOwnerEmail"
                                control={control}
                                render={() => (
                                    <OutlinedInput required
                                                   fullWidth
                                                   autoFocus
                                                   name="newOwnerEmail"
                                                   label="New owner email address"
                                                   type="email"
                                                   id="newOwnerEmail"
                                                   placeholder="new@email.com"
                                                   autoComplete="off"
                                                   onChange={e => doSearch(e)}
                                                   endAdornment={
                                                       <InputAdornment position="end">
                                                           {isCheckUserLoading ?
                                                               <CircularProgress size="1.3rem"
                                                                                 className="circular-progress-primary ml-0"/> : null}
                                                       </InputAdornment>
                                                   }
                                    />)}
                                defaultValue=""
                                error={!!errors?.newOwnerEmail}/>
                </FormControl>
                {Object.keys(ownerData).length ?
                    <div className="mt-3 d-flex align-items-center">
                        <Avatar alt="Profile"
                                src={ownerData?.profile_picture}
                                className={"avatar"}/>
                        <Typography variant={"h5"} className="ml-2 fs-16">
                            {ownerData?.full_name}
                        </Typography>
                    </div> : null}
            </DialogContent>
            <DialogActions className="mb-3 pt-0 px-5">
                <Button onClick={handleClose} color="primary">
                    {modal?.cancelTitle || "Cancel"}
                </Button>
                <Button type={"submit"} className="ml-3" color="primary" variant="contained"
                        disabled={modal?.isLoading || isCheckUserLoading || !Object.keys(ownerData).length}>
                    {modal?.isLoading ?
                        <CircularProgress size="1.3rem"
                                          className="circular-progress-primary ml-0"/> : modal?.submitTitle || "Ok"}
                </Button>
            </DialogActions>
        </form>
    </Dialog>;
};

export default connect(null, {
    OpenModal,
    CommonDispatcher,
    CheckUserExists
})(React.memo(ChangeOwnershipModal));