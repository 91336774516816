import {
    Button,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import AutocompleteComponent from "../../InputComponents/AutoCompleteAddress";
import { Controller, useForm } from "react-hook-form";
import { fetchUserProfile, updateUserProfile } from "../../../redux/Dashboard/actions";
import ImageUploadComponent from "../../ImageUploadComponent";
import useStateRef from "react-usestateref";
import { useCookies } from "react-cookie";
import RenderDatePicker from "../../InputComponents/DatePickerComponent/RenderDatePicker";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: "5rem auto"
        }
    })
);

function UpdateProfileInfo(props: any) {
    const classes = useStyles();

    const [coordinates, setCoordinates] = useState({
        lat: 51.50853, lng: -0.12574
    });
    const [address, setAddress, refAddress] = useStateRef({city: "", country: "", address: ""});
    const [profileDetails, setProfileDetails, refProfileDetails] = useStateRef({} as any);
    const [cookies] = useCookies(["token"]);
    const [isLoading, setIsLoading] = useState(true);
    const {control, handleSubmit} = useForm();

    const onSubmit = (obj: any) => {
        let data = {...obj, dob: moment(obj?.dob).format("YYYY-MM-DD"), user_name: obj?.user_name.trim()};
        setIsLoading(true);
        props?.updateUserProfile(cookies?.token, {
            ...refProfileDetails?.current,
            ...data,
            city: refAddress?.current?.city || null,
            country: refAddress?.current?.country || null,
            location: refAddress?.current?.address || null
        }).then(data => {
            setProfileDetails(data);
            setAddress({
                city: data?.city,
                country: data?.country,
                address: data?.location
            });
            setIsLoading(false);
            alert("Data saved.");
        }).catch(() => {
            setIsLoading(false);
            alert("Failed to update user profile.");
        });
    };

    const onInvalid = () => {
        alert("Please verify the input nd try again.");
    };

    useEffect(() => {
        props?.fetchUserProfile(cookies?.token, props?.match?.params?.id).then(data => {
            setProfileDetails(data);
            setAddress({
                city: data?.city,
                country: data?.country,
                address: data?.location
            });
            setIsLoading(false);
        });
        // eslint-disable-next-line
    }, [props?.match?.params?.id, cookies]);

    return <Container maxWidth="sm" className={classes.root}>
        <Grid item>
            <div>
                {isLoading ? <CircularProgress style={{
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    margin: "-25px 0 0 -25px"
                }}/> : <>
                    <ImageUploadComponent token={cookies?.token} {...refProfileDetails?.current}/>
                    <form onSubmit={handleSubmit(onSubmit, onInvalid)}>

                        <Controller
                            name="full_name"
                            as={
                                <TextField
                                    name="full_name"
                                    label="Full Name"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"/>
                            }
                            control={control}
                            defaultValue={profileDetails?.full_name}
                            rules={{
                                required: true
                            }}
                        />

                        <Controller
                            name="title"
                            as={
                                <TextField
                                    name="title"
                                    label="Title"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                            }
                            control={control}
                            defaultValue={profileDetails?.title}
                            rules={{
                                required: true
                            }}
                        />

                        <div style={{marginTop: "10px", minWidth: "100%"}}>
                            <AutocompleteComponent id="__location"
                                                   coordinates={coordinates}
                                                   defaultValue={address?.address}
                                                   setCoordinates={setCoordinates}
                                                   setAddress={setAddress}
                                                   hideMap={true}
                                                   inputLabel={"Address"}/>

                        </div>

                        <div style={{marginTop: "10px", minWidth: "100%"}}>
                            <Controller
                                name="user_name"
                                as={
                                    <TextField
                                        InputProps={{
                                            startAdornment: <InputAdornment
                                                position="start">luxexpats.lu/</InputAdornment>
                                        }}
                                        name="user_name"
                                        label="Username"
                                        variant="outlined"
                                        autoComplete={"off"}
                                        fullWidth
                                        margin="normal"
                                    />
                                }
                                control={control}
                                defaultValue={profileDetails?.user_name}
                                rules={{
                                    required: true,
                                    validate: {
                                        isTaken: async (value) => {
                                            try {
                                                props?.isUserNameTaken(value.trim()).then(r => {
                                                    return !r?.response.isTaken;
                                                });
                                            } catch (e) {
                                                return true;
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>

                        <Controller
                            name="about"
                            as={
                                <TextField multiline
                                           name="about"
                                           label="About"
                                           variant="outlined"
                                           fullWidth
                                           margin="normal"/>
                            }
                            control={control}
                            defaultValue={profileDetails?.about}
                            rules={{
                                required: true
                            }}
                        />

                        <FormControl style={{minWidth: "100%"}} margin="normal">
                            <InputLabel
                                style={{
                                    background: "#fff",
                                    padding: "0 8px"
                                }}
                                htmlFor="gender-select"
                                variant="outlined">
                                Gender
                            </InputLabel>
                            <Controller
                                control={control}
                                defaultValue={profileDetails?.gender}
                                name="gender"
                                style={{minWidth: "100px"}}
                                as={
                                    <Select
                                        variant="outlined"
                                        id="gender-select"
                                        style={{minWidth: "100px"}}
                                    >
                                        <MenuItem value={"MALE"}>Male</MenuItem>
                                        <MenuItem value={"FEMALE"}>Female</MenuItem>
                                    </Select>
                                }
                            />
                        </FormControl>

                        <div style={{marginTop: "20px", minWidth: "100%"}}>
                            <Controller
                                name="dob"
                                as={
                                    <RenderDatePicker id="dob"
                                                      name="dob"
                                                      showInputLabel={false}
                                                      removeMarginAndPadding={true}
                                                      label="Birth date"
                                                      disableFuture={true}
                                                      value={profileDetails?.dob}
                                                      defaultValue={profileDetails?.dob}
                                                      format="DD-MM-YYYY"/>
                                }
                                control={control}
                                defaultValue={profileDetails?.dob || moment().add(-18, "year").format("YYYY-MM-DD")}
                                rules={{
                                    required: true,
                                    validate: {
                                        18: (date) => moment().diff(date, "years") >= 18,
                                        alive: (date) => moment().diff(date, "years") <= 150
                                    }
                                }}
                            />
                        </div>


                        <div style={{marginTop: "20px"}}>
                            <Button type="submit" variant="contained" color="primary" className="float-right">
                                Update Profile
                            </Button>
                        </div>
                    </form>
                </>}
            </div>
        </Grid>
    </Container>;
}

export default (connect(null, {
    fetchUserProfile,
    updateUserProfile
})(UpdateProfileInfo));
