import {API_PATH} from "../../constants";
import axios from "axios";
import {SET_CATEGORIES_DATA, SET_MEET_PEOPLE_INTERESTS_DATA} from "./actionTypes";

export const OpenModal = (type, payload) => {
    return dispatch => {
        dispatch({type, payload});
    };
};

export const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const createAllCommonCategories = (categoriesJson = []) => {
    let commonCategories = {
        services: [],
        buySell: [],
        offers: [],
        events: [],
        stories: [],
        realEstate: [],
        meetPeople: [],
        requestOffer: []
    };
    if (categoriesJson && categoriesJson.length) {
        categoriesJson.forEach(d => {
            if (d.type === "OFFER") {
                createObject(commonCategories, "offers", d);
            }
            if (d.type === "BUY_SELL") {
                createObject(commonCategories, "buySell", d);
            }
            if (d.type === "SERVICE") {
                createObject(commonCategories, "services", d);
            }
            if (d.type === "EVENT") {
                createObject(commonCategories, "events", d);
            }
            if (d.type === "STORIES") {
                createObject(commonCategories, "stories", d);
            }
            if (d.type === "REAL_ESTATE") {
                createObject(commonCategories, "realEstate", d);
            }
            if (d.type === "MEET_PEOPLE") {
                createObject(commonCategories, "meetPeople", d);
            }
            if (d.type === "REQUEST_OFFER") {
                createObject(commonCategories, "requestOffer", d);
            }
        });
    }
    createRealEstateCategories(commonCategories["realEstate"]);
    return commonCategories;
};

const createObject = (commonCategories, key, d) => {
    return commonCategories[key].push({
        title: d?.label,
        value: d?.category_id,
        name: d?.url_identifier,
        type: d?.type,
        subCategories: d?.sub_categories
    });
};

export const createRealEstateCategories = (categoriesData) => {
    if (!categoriesData && !categoriesData?.length)
        return [];
    else
        return categoriesData.map(d => {
            const subCategories = [];
            if (d?.subCategories) {
                d?.subCategories.forEach(o => {
                    if (d?.category_id === o?.category_id) {
                        subCategories.push({
                            title: o?.label,
                            value: o?.sub_category_id
                        });
                    }
                });
            }
            d.subCategories = subCategories;
            return d;
        });
};

export const sortData = (data, label = "name") => {
    if (data && data.length) {
        return data.sort((a, b) => {
            if (a[label] < b[label]) {
                return -1;
            }
            if (a[label] > b[label]) {
                return 1;
            }
            return 0;
        });
    } else return [];
};

export const GetReduxState = (store) => {
    return (typeof store !== "undefined" && store !== null) ? store.getState() : {};
};

export const CommonDispatcher = (type, payload) => {
    return dispatch => {
        dispatch({type, payload});
    };
};

export const CheckUserExists = (token: string, data: object) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: "post",
                data,
                url: `${API_PATH}/admin/get-user-email`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: "success", response};
        } catch (e) {
            return {type: "failed"};
        }
    };
};

export const GetCategories = (token: string) => {
    return async (dispatch) => {
        try {
            const config = {
                method: "get",
                url: `${API_PATH}/metadata/categories`,
                headers: {Authorization: token}
            };
            const payload = (await axios(config as any))?.data?.data?.categories;
            dispatch({
                type: SET_CATEGORIES_DATA,
                payload
            });
            return payload;
        } catch (e) {
            return {type: "failed"};
        }
    };
};

export const GetMeetPeopleInterests = (token: string) => {
    return async (dispatch) => {
        try {
            const config = {
                method: "get",
                url: `${API_PATH}/metadata/interests`,
                headers: {Authorization: token}
            };
            const payload = (await axios(config as any))?.data?.data?.interest;
            dispatch({
                type: SET_MEET_PEOPLE_INTERESTS_DATA,
                payload
            });
            return payload;
        } catch (e) {
            return {type: "failed"};
        }
    };
};