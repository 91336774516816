import * as ACTIONS from './actionTypes';

const initialValues = {
    tableHeader: [
        {id: 'actions', label: 'Actions'},
        {id: 'createdAt', label: 'Listing Date'},
        {id: 'status', label: 'Payments Status'},
        {id: 'actionStatus', label: 'Post Action'},
        {id: 'expiry_date', label: 'Expiry Date'},
        {id: 'type', label: 'Listing Type'},
        {id: 'title', label: 'Post Title'},
        {id: 'views', label: 'Number of Views'},
        {id: 'owner_name', label: 'User Name'},
        {id: 'owner_email', label: 'User Email'},
    ],
    listingsData: {
        current_page: 0,
        total_pages: 0,
        posts: [],
        count: 0
    }
}

const BuySellReducer = (state = initialValues, action: any) => {
    switch (action.type) {
        case ACTIONS.SET_BUY_SELL_DATA:
            return {
                ...state,
                listingsData: {
                    posts: action?.payload?.posts,
                    count: action?.payload?.count,
                    current_page: action?.payload?.current_page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                }
            };
        default:
            return state;
    }
};

export default BuySellReducer;