import React from 'react';
import Login from "./components/Login";
import {useCookies} from "react-cookie";
import Router from "./components/Router";
import styles from "./App.module.css";
import {theme} from "./muiTheme";
import {ThemeProvider as MuiThemeProvider} from "@material-ui/core/styles";

function App() {
    const [cookies] = useCookies(['token']);
    return (
        <MuiThemeProvider theme={theme}>
            <div className={styles.body}>
                {cookies.token ? <Router/> : <Login/>}
            </div>
        </MuiThemeProvider>
    );
}

export default App;
