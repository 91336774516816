import * as ACTIONS from "./actionTypes";

const initialValues = {
    tableHeader: [
        {id: "actions", label: "Actions"},
        {id: "category_id", label: "Category"},
        {id: "type", label: "Type"},
        {id: "description", label: "Written Message"},
        {id: "is_online", label: "Is Online"},
        {id: "is_in_person", label: "Is In Person"},
        {id: "is_free", label: "Is Free"},
        {id: "display_price", label: "Display Price"},
        {id: "start_date", label: "Start Date"},
        {id: "end_date", label: "End Date"},
        {id: "posted_on", label: "Posted On"},
        {id: "full_name", label: "Name"},
        {id: "email", label: "Email"},
        {id: "status", label: "Account Status"}
    ],
    listingsData: {
        current_page: 0,
        total_pages: 0,
        posts: [],
        count: 0
    }
};

const RequestOfferReducer = (state = initialValues, action: any) => {
    switch (action.type) {
        case ACTIONS.SET_REQUEST_OFFER_DATA:
            return {
                ...state,
                listingsData: {
                    posts: action?.payload?.posts,
                    count: action?.payload?.count,
                    current_page: action?.payload?.current_page,
                    page_size: action?.payload?.page_size,
                    total_pages: action?.payload?.total_pages
                }
            };
        default:
            return state;
    }
};

export default RequestOfferReducer;