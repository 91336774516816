import * as Actions from "./actionTypes";
import axios from "axios";
import {API_PATH} from "../../constants";

export const GetStoriesList = (token: string, page_number: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/stories`,
                headers: {
                    Authorization: token
                },
                data: {page_number}
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_STORIES_DATA,
                payload
            });
        } catch (e) {
            throw e;
        }
    };
};

export const DeleteStories = (token: string, story_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {story_id},
                url: `${API_PATH}/admin/delete/stories`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const UpdateStories = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update/stories`,
                headers: {Authorization: token}
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const VerifyStories = (token: string, story_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: {story_id},
                url: `${API_PATH}/admin/verify/stories`,
                headers: {Authorization: token}
            };
            await axios(config as any);
        } catch (e) {
            throw e;
        }
    };
};